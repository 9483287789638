export const editClassName = (ref, conditions, additionalName) => {
    if (ref) {
        if (conditions) {
            ref.current.className = ref.current.className + ' ' + additionalName;
        } else {
            removeClassName(ref, additionalName);
        }
    }
}
export const removeClassName = (ref, additionalName) => {
    ref.current.className = ref.current.className.replace(additionalName, '');
}

