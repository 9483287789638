import TransactorService from "../Services/TransactorService";
import { useState } from "react";
import VeloAPIService from "../Services/VeloAPIService";
const SOBBar = ({ bgColor, resetSObBanner }) => {
    const [p, setP] = useState(null)
    const [queriedTransactor, setQueriedTransactor] = useState(false);
    const OfferActive = sessionStorage.getItem("OfferActive");
    const fromExternalLink = sessionStorage.getItem("fromExternalLink");
    if (OfferActive === 'true'|| fromExternalLink === 'true' ) {
        resetSObBanner();
        return;
    }

    if (!queriedTransactor) {
        const sob = sessionStorage.getItem("sourceOfBusinessId");
        TransactorService.fetchSourceOfBusinessDiscountBannerHtml(sob).then((data) => {
       
            if (data?.value === null || data?.value?.html === null || data?.value?.html === undefined ||data?.value?.html =="" ) {
                sessionStorage.removeItem("sourceOfBusinessId");
                VeloAPIService.CheckAndApplyOffer();
                resetSObBanner();
                return;
            } 
            else {
                setP(data?.value?.html);
            }
        });
        setQueriedTransactor(true);
    }
    if (p === null) {
        return <></>
    }

    return (
        <div className={`container-fluid SOBBar fixed-top ${bgColor}`} style={{ 'zIndex': '1040' }} dangerouslySetInnerHTML={{ __html: p }}>

        </div>
    );
}

export default SOBBar;