import { useEffect } from "react";
import { seoTags } from "../../Shared/SeoEdit";
import TopNavBar from "../../Shared/TopNavBar";
import RegularBanner from "../SHARED/RegularBanner";
import WhatOurCustomersSay from "../SHARED/WhatOurCustomersSay";
import { Helmet } from "react-helmet";

const VulnerabilityPolicy = () => {
  useEffect(() => {
    seoTags(
      "Vulnerability Policy",
      "This poklcy provides a clear explanation of how we can support our vulnerable customers.",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);
  return (
    <div className="container-fluid">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.velosure.co.uk/VulnerabilityPolicy"
        />
      </Helmet>
      <TopNavBar theme={"white"} />
      <RegularBanner
        headlineLine1={""}
        headlineLine2={"Vulnerability"}
        subheadlineLine1={""}
        subheadlineLine2={""}
        hasCTA={"false"}
        CTAText={"Get a quote"}
        requiresSpacer={true} // this is for the edge cases where title/sub title are one line each AND no CTA - Cog gets misplaced
        requiresExtraSpacer={true}
      />
      <div className="container-fluid greyBG oh pr">
        <div className="container officialDocs">
          <div className="row">
            <div className="col-12 mt-5 mb-5">
              <h3>Vulnerable Customers</h3>
              <p>
                Life can feel a little overwhelming sometimes, and it’s not
                always easy to deal with everything by yourself. Whether you
                have specific needs, are living with a health condition, or
                you’re going through a difficult time, we want to make it easy
                for you to manage your insurance needs. Anything you share with
                us will be kept confidential; we would only use the information
                to improve our support whilst looking after you and your policy.
              </p>
              <p>
                We want you to know that we’re here if you need us, and we’ll
                always do our best to make sure you get the help and support you
                deserve. We can assist in many ways and will gladly tailor our
                support to suit you.
              </p>
              <p>Here’s how we can help.</p>

              <h3>Accessibility</h3>
              <p>
                Should you require documents in an alternative format, please
                contact us by going to ‘Get in touch’ section of our website and
                we will endeavour to meet your request.
              </p>

              <h3>Bereavement</h3>
              <p>
                Firstly, we’re so sorry for your loss. We understand that
                there’s a lot to think about right now. Not only are you
                managing your emotions at a very difficult time, there’s also
                much to organise. We know that finances are the last thing
                you’ll want to be worrying about. That’s why we’ll be here to
                assist you as much as we can or point you towards dedicated
                support.
              </p>
              <p>Some of the things we can do include:</p>
              <ul>
                <li>Sending summaries of our conversations by email</li>
                <li>Calling you back if you’re feeling overwhelmed</li>
                <li>
                  Giving you as much time as you need to share your situation,
                  process information, and ask questions
                </li>
                <li>Offering you a dedicated point of contact</li>
              </ul>
              <p>
                We will always try our best to offer every possible solution to
                any issues.
              </p>

              <h3>Health and Wellbeing</h3>
              <p>
                Mental or physical health difficulties could become part of life
                for many of us at some stage in the future, and such things can
                make daily tasks or dealing with financial matters harder. We
                understand that life isn’t always smooth sailing, which is why
                we aim to make insurance accessible to all.
              </p>
              <p>
                <u>Mental health concerns</u>
              </p>
              <p>
                Sometimes it can be helpful to tell companies like ours if you
                experience issues with your mental health. We can take steps to
                assist you if it affects how you’re able to communicate with us
                or manage your account. Your wellbeing is important to us; so,
                if you’re struggling, please let us know so we can adjust how we
                communicate with you and offer further support.
              </p>
              <p>Some of the things we can do include:</p>
              <ul>
                <li>
                  Sending information by email to confirm or summarise what’s
                  been discussed
                </li>
                <li>
                  Speaking to a third party (with your permission) if it’s
                  easier for you
                </li>
                <li>
                  Taking payments manually if making payments online is a
                  struggle
                </li>
                <li>
                  Offering to call back when you’re with someone who can help or
                  pass on information
                </li>
              </ul>
              <p>
                <u>Serious or long-term health conditions</u>
              </p>
              <p>
                If you, a friend, or a loved one has been diagnosed with a
                serious illness, you don’t have to deal with it on your own. We
                understand that changes may need to be made to your lifestyle or
                finances, and we want you to know we’re here to make things as
                hassle-free as possible.
              </p>
              <p>
                Alternatively, you could get in touch with an organisation such
                as
              </p>
              <ul>
                <li>
                <a href="https://www.samaritans.org/" target="_blank" rel="noreferrer">Samaritans</a> : Call
                  116 123 for free, 24 hours a day, 365 days a year or
                </li>
                <li>
                  email <a href="mailto:jo@samaritans.org">jo@samaritans.org</a>{" "}
                  or
                </li>
                <li>
                  <a href="https://www.samaritans.org/how-we-can-help/contact-samaritan/chat-online/" target="_blank" rel="noreferrer">
                    Chat online
                  </a>
                </li>
              </ul>

              <h3>Moving Home</h3>
              <p>
                Whether you’re just starting out or buying your forever home,
                there’s a lot to arrange when you’re moving house. Speak to a
                member of our team and we’ll be able to assist you if you need
                to update your existing insurance policies or take out new ones.
              </p>
              <p>
                If you’re moving into temporary accommodation, let us know. We
                want to make sure you’re covered if you need to make a claim,
                and that could potentially mean updating your policies or
                starting new ones.
              </p>

              <h3>Power of attorney</h3>
              <p>
                There are many reasons why you might ask a close family member
                or friend to manage your affairs on your behalf. It could be a
                short-term plan to help cover bills during a hospital stay, or
                you may have been diagnosed with a lifelong condition, such as
                dementia or Alzheimer’s, and require help from someone who’ll
                legally make decisions on your behalf. This is known as Power of
                Attorney.
              </p>
              <p>
                If managing financial matters by yourself is a worry, giving
                Power of Attorney to someone you trust could give you that peace
                of mind that you’re being taken care of. When you appoint
                somebody to take responsibility of your finances, let us know
                and we’ll do all we can to make the transition as smooth as
                possible.
              </p>
              <p>
                To allow your nominee to discuss your policy on your behalf,
                give us a call to let us know who you’d like to speak for you.
              </p>

              <h3>Redundancy</h3>
              <p>
                If you’ve been made redundant or had your hours reduced
                unexpectedly, it’s probably come as quite a shock. Give yourself
                time to process your situation and then tell us what’s happened.
                During this time of uncertainty, you’ll be keeping a close eye
                on your finances and may have to deal with financial shortfalls.
                The important thing to note is that we can point you in the
                direction of advice and support, so you can navigate your way to
                brighter days ahead.
              </p>

              <h3>Vehicle written off/stolen/burnt out</h3>
              <p>
                Losing access to your vehicle without any notice can become very
                stressful, potentially effecting how you will get to work, get
                to your appointments, see family and friends amongst many
                things. You may be worried about how you will meet any existing
                finance/loan payments and how you will replace your vehicle. We
                understand you may have lots of arrangements to make and if we
                are dealing with the claim on your behalf, we will do our upmost
                to expediate the claim and get you back on the road.
              </p>

              <h3>Contact us</h3>
              <p>
                We welcome your comments and suggestions as to how we can
                improve our service. You can contact our Customer Service Team
                by going to the ‘Contact us’ section of our website.
              </p>
            </div>
          </div>
        </div>
      </div>
      <WhatOurCustomersSay />
    </div>
  );
};

export default VulnerabilityPolicy;
