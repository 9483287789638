import Question from "./Question";
import { useEffect, useState } from "react";
import Spinner from "../../Shared/Spinner";

const FAQSection = () => {
  const [structuredData, setStructuredData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    generateAndDefaultData();
  }, []);

  const generateAndDefaultData = async () => {
    const options = {
      method: "GET",
      headers: {
        "X-API-KEY": process.env.REACT_APP_VELOSURE_API_KEY,
        "content-type": "application/json",
      },
    };
    const categories = await fetch(
      `${process.env.REACT_APP_VELOSURE_API_URL}/api/ConnexusCMS/Categories/GetCategoriesByType/${process.env.REACT_APP_FAQS_CATEGORY_TYPE}`, options
    )
      .then((response) => response.json())
      .then((data) => data);
    const faqs = await fetch(
      `${process.env.REACT_APP_VELOSURE_API_URL}/api/ConnexusCMS/FAQs/GetFAQs/${process.env.REACT_APP_CONNEXUS_BRAND}`, options
    )
      .then((response) => response.json())
      .then((data) => data);

    categories.forEach((category) => {
      const filteredFaqs = faqs.filter(
        (faq) => faq.category.id === category.id
      );
      setStructuredData((prevState) => [
        ...prevState,
        { name: category.name, faqs: filteredFaqs },
      ]);
    });

    // Default to show all
    setActiveCategory(0);
    setFilteredData(faqs.filter(
      (faq) => faq.category.id === categories[0].id
    ));
    setLoading(false);
  };

  const handleCategoryFilterChange = (index) => {
    setActiveCategory(index);
    setFilteredData(structuredData[index].faqs);
  };

  if (loading) return <div className="container my-5 py-5 text-center ">   <h4> <Spinner colour="velo-blue" />  Loading the FAQs    </h4></div >

  return (
    <div className="container mt-sm-5 mt-3 mb-5 oh ">
      <div className="row ">
        {structuredData.map((object, index) => {
          return (
            <div className="col-3 col-md-2 mb-sm-5 mb-3" key={index}>
              <button
                className={
                  index === activeCategory
                    ? "btn btn-seconday  primaryFocussed filterBlogButton"
                    : "btn btn-white  filterBlogButton"
                }
                onClick={() => handleCategoryFilterChange(index)}
              >
                {object.name}
              </button>
            </div>
          );
        })}
      </div>

      <Question filteredData={filteredData} />

    </div>
  );
};

export default FAQSection;
