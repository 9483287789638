// Service for communicating with REST APIs
import LoggingService from "./LoggingService";
const RestApiCommBaseService = {
  // Deserialize JSON response received from API and handle errors
  // param response is the response
  handleResponse: (response) => {
    //console.log(response);
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        const error =
          (data && data.message) ||
          (data && data.error_description) ||
          response.statusText;
          LoggingService.logError(error);
        return Promise.reject(error);
      }

      return data;
    });
  },
};

export default RestApiCommBaseService;
