import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import useRealex from "../../CUSTOMHOOKS/useRealex";
import { useHistory, useLocation } from "react-router-dom";
import PaymentService from "../../Services/PaymentService";
import TransactorService from "../../Services/TransactorService";
import useRiskModelAdaptor from "../../CUSTOMHOOKS/useRiskModelAdaptor";
import DDForm from "./DDForm";
import DDGuarantee from "./DDGuarantee";
import LoggingService from "../../Services/LoggingService";
import VeloAPIService from "../../Services/VeloAPIService";
import SanctionsSearchService from "../../Services/SanctionsSearchService";

const Payments = ({ fromExternalLink = false, fromAggregator = false, setRotate, showPaymentWindow, setShowPaymentWindow, setPending }) => {
  let globalPayAttemptCount = 0;
  const { search } = useLocation();
  const [gState, setGState] = useContext(JourneyContext);
  const RealexHpp = useRealex();
  const history = useHistory();
  const riskModel = useRiskModelAdaptor(gState);

  const [error, setError] = useState("");

  const [
    assumptionsAndDeclarationsAllChecked,
    setAssumptionsAndDeclarationsAllChecked,
  ] = useState(false);
  const amendDetails = (
    <Link
      className="btn btn-wider btn-red float-start"
      to={`/get-a-quote`}
      id="amend-details"
      onClick={() => {
        setGState({
          ...gState,
          yourCoverCrumb: 1,
          yourDetailsCrumb: 1,
          yourQuoteCrumb: 1,
          paymentCrumb: 0,
        });
      }}
    >
      Amend details
    </Link>
  );

  useEffect(() => {
    // users could reset the page, clearing the journey context - if this happens we want them to be returned to the step one. We'll use the bike count to test for a reset
    if (gState.bikes.length === 0) {
      setGState({ yourQuoteCrumb: 0 });
      return history.push(`/get-a-quote${search}`);
    }
  });

  useEffect(() => {
    const allTicked =
      gState.bikes.every((e) => e.lockChecked) &&
      (!gState.bikes.some((s) => s.isElectric) || gState.eBikeTicked) &&
      gState.assumptionsTicked &&
      gState.storageLocationTicked &&
      gState.readDocumentsTicked &&
      gState.paymentTypeIsAnnual !== null;
    setAssumptionsAndDeclarationsAllChecked(allTicked);
    if (allTicked) {
      setGState({ ...gState, clickedPayNow: false });
    } else {
      setShowPaymentWindow(false);
    }
  }, [gState.bikes, gState.assumptionsTicked, gState.storageLocationTicked, gState.readDocumentsTicked, gState.paymentTypeIsAnnual]);

  const formik = useFormik({
    initialValues: {
      accountName: "",
      accountNumber: "",
      accountSortCode: "",
      accountBankName: gState.accountBankName,
    },
    validationSchema: Yup.object({
      accountName: Yup.string()
        .required("Account name is required")

        .min(2, "Account name is too short")
        .max(40, "Account name is too long"),
      accountNumber: Yup.string()
        .required("Account number is required")
        .matches(/^\d+$/, 'Account number must only contain numbers')
        .min(8, "Account number is too short")
        .max(8, "Account number is too long"),
      accountSortCode: Yup.string()
        .required("Sort code is required")
        .matches(/^\d+$/, 'Sort code must only contain numbers')
        .min(6, "Sort code is too short")
        .max(6, "Sort code is too long"),
      accountBankName: Yup.string().required(
        "We cannot find a bank account using these details"
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      // set State
      setGState({
        ...gState,
        accountName: values.accountName,
        accountNumber: values.accountNumber,
        accountSortCode: values.accountSortCode,
        // accountBankName: values.accountBankName,
      });
      // resetForm({ values: "" });
    },
  });

  const annualPaymentSection = <div id="card-payment-form">
    <div className="d-flex justify-content-center">
      <h4>
        {"Card Details "}{" "}
        <span className="blueFont"> Annual Payment</span>
      </h4>
    </div>
    <div className="col-12">
      <iframe id="payIframe" title="payIframe"></iframe>
      {error && (
        <p>
          {" "}
          <small className="redFont mt-1 d-flex  justify-content-center">
            {error}
          </small>{" "}
        </p>
      )}
    </div>
  </div>;

  const monthlyPaymentSection = <section className="container container_narrow " >
    <div className=" mt-3 mb-4 ">
      <div id="dd-payment-form">
        <div className="col-12">
          <DDForm formik={formik} />
        </div>
      </div>
      {error && <small className="redFont mt-1 ">{error}</small>}
    </div>
  </section>;

  const [paymentSection, setPaymentSection] = useState(annualPaymentSection);

  const handleClickPayNow = async (e) => {
    e.preventDefault();
    setGState({ ...gState, clickedPayNow: true });
    const allTicked =
    gState.bikes.every((e) => e.lockChecked) &&
    (!gState.bikes.some((s) => s.isElectric) || gState.eBikeTicked) &&
    gState.assumptionsTicked &&
    gState.storageLocationTicked &&
    gState.readDocumentsTicked &&
    gState.paymentTypeIsAnnual !== null;

    if (!allTicked) {
      setShowPaymentWindow(false);
      if (!gState.assumptionsTicked) {
        const element = document.getElementById("assumptionsTicked");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
      if (gState.bikes.some((s) => s.isElectric) && !gState.eBikeTicked) {
        const element = document.getElementById("eBikeTicked");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
      if (!gState.readDocumentsTicked) {
        const element = document.getElementById("readDocumentsTicked");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
      if (!gState.storageLocationTicked) {
        const element = document.getElementById("storageLocationTicked");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
      if (!gState.bikes.every((e) => e.lockChecked)) {
        const element = document.getElementById("lockChecked");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
    }
    if (process.env.REACT_APP_SanctionsSearchDisabled === 'false') {
      SanctionsSearchService.SanctionSearch(gState).then((data) => {
        if (data.Error) {
          history.push("/QuoteReferred?quoteReference=" + gState.quoteReference);
          return;
        }
        if (data.Failure) {
          history.push("/QuoteReferred?quoteReference=" + gState.quoteReference.replace("WEB", "REF"));
          return;
        }

        setShowPaymentWindow(allTicked);
        if (gState.paymentTypeIsAnnual === true) {
          getGlobalPayHpp();
        }
      }).catch((err) => {
        history.push("/QuoteReferred?quoteReference=" + gState.quoteReference);
        return;
      });
    } else {  
      setShowPaymentWindow(allTicked);
      if (gState.paymentTypeIsAnnual === true) {
        getGlobalPayHpp();
      }
    }
    if (gState.paymentTypeIsAnnual == null) {
      setShowPaymentWindow(false);
      const element = document.getElementById("paymentButtons");
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }
  }

  const handleBuyNowClick = async (e) => {
    e.preventDefault();

    if (formik.isValid && formik.dirty) {
      setPending(true);
      setRotate(true);
      setGState({ ...gState, DDFormIsValid: true });
    } else {
      setGState({ ...gState, DDFormIsValid: false });
      setPending(false);
      setRotate(false);
      const element = document.getElementById("ddForm");
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      formik.handleSubmit();
      return;
    }
    riskModel.paymentDetails.paymentTypeId =
      process.env.REACT_APP_DIRECT_DEBIT_PAYMENT_TYPE_ID;
    riskModel.paymentDetails.bankDetails = {
      accountNumber: formik.values.accountNumber,
      sortCode: formik.values.accountSortCode,
      AccountHolderName: formik.values.accountName,
    }
    LoggingService.logInfo(
      `DD payment details taken for Quote: ${gState.quoteReference} attempting to incept the policy`
    );
    const riskModelString = JSON.stringify(riskModel);
    tryInceptPolicy(
      riskModelString,
      setPending,
      setRotate,
      history,
      setGState,
      gState,
      fromAggregator
    );
  };

  const getGlobalPayHpp = () => {
    PaymentService.getHppAccessToken(
      PaymentService.getCycleInsurancePurchase(gState)
    ).then((accessTokenData) => {
      // Got the access token and carry on with processing the payment
      if (accessTokenData.success) {
        RealexHpp.setHppUrl(process.env.REACT_APP_GLOBAL_PAY_SERVICE_URL);
        const paymentData = JSON.parse(accessTokenData.value);

        RealexHpp.embedded.init(
          "autoload",
          "payIframe",
          (answer, close) => {
            //Ensure correct single 'message' event gets processed
            if (paymentData.ORDER_ID === answer.ORDER_ID) {
              PaymentService.validatePayment(answer).then(
                (validatedPaymentData) => {
                  // Payment confirmed, set up the policy in Transactor
                  if (validatedPaymentData.success) {
                    LoggingService.logInfo(
                      `payment taken for Quote: ${gState.quoteReference} attempting to incept the policy`
                    );
                    // now attempt to incept the policy now that payment has been taken
                    riskModel.globalPayTransactionId =
                      validatedPaymentData?.value?.transactionId;
                    riskModel.paymentDetails.paymentTypeId =
                      process.env.REACT_APP_CARD_PAYMENT_TYPE_ID;
                    const riskModelString = JSON.stringify(riskModel);
                    setPending(true);
                    setRotate(true);
                    tryInceptPolicy(
                      riskModelString,
                      setPending,
                      setRotate,
                      history,
                      setGState,
                      gState,
                      fromAggregator
                    );
                  }
                  // Check if user reached max limit of retries
                  // Remove and recreate the iframe as GlobalPay hides the form if allowed another retry
                  // If max retries we want to redirect to payment error page
                  else {
                    // setGState({ ...gState, paymentSuccessful: false });
                    if (
                      globalPayAttemptCount <
                      process.env.REACT_APP_GLOBAL_PAY_RETRY_COUNT
                    ) {
                      globalPayAttemptCount++;
                      LoggingService.logWarning(
                        `These global pay details could not be verified for Quote: ${gState.quoteReference} `
                      );
                      setError(
                        "Something went wrong processing your payment, please check your details are correct and try again."
                      );
                      PaymentService.rebuildPaymentIframe();
                      getGlobalPayHpp();
                    } else {
                      LoggingService.logError(
                        `These global pay details could not be verified ${process.env.REACT_APP_GLOBAL_PAY_RETRY_COUNT} times user redirect to error page, response: ${JSON.stringify(validatedPaymentData)}, quote reference: ${gState.quoteReference} `
                      );
                      history.push("/PaymentError?quoteReference=" + gState.quoteReference);
                    }
                  }
                }
              );
            } else{
              if (
                globalPayAttemptCount <
                process.env.REACT_APP_GLOBAL_PAY_RETRY_COUNT
              ) {
                globalPayAttemptCount++;
                LoggingService.logWarning(
                  `These global pay details could not be verified for Quote: ${gState.quoteReference} `
                );
                setError(
                  "Something went wrong processing your payment, please check your details are correct and try again."
                );
                PaymentService.rebuildPaymentIframe();
                getGlobalPayHpp();
              } else {
                LoggingService.logError(
                  `These global pay details could not be verified ${process.env.REACT_APP_GLOBAL_PAY_RETRY_COUNT} times user redirect to error page, response: ${JSON.stringify(answer)}, quote reference: ${gState.quoteReference} `
                );
                history.push("/PaymentError?quoteReference=" + gState.quoteReference);
              }
            }
          },
          paymentData,
          {}
        );
      }
      // Getting the access token failed, take user to Payment Error page
      else {
        LoggingService.logError(
          `These global pay details could not be verified ${process.env.REACT_APP_GLOBAL_PAY_RETRY_COUNT} times user redirect to error page, response: ${JSON.stringify(accessTokenData)}, quote reference: ${gState.quoteReference} `
        );
        history.push("/PaymentError?quoteReference=" + gState.quoteReference);
      }
    });
  };

  useEffect(() => {
    // add the bike values in the bikes array
    let runningTotal = 0;
    gState.bikes.forEach((bike) => {
      runningTotal = parseInt(runningTotal) + parseInt(bike.value);
    });

    setGState({
      ...gState,
      combinedHomeValue: runningTotal,
      generateQuote: false,
      yourDetailsCrumb:
        gState.yourDetailsCrumb === 2 ? 1 : gState.yourDetailsCrumb,
      yourQuoteCrumb: gState.yourQuoteCrumb === 2 ? 1 : gState.yourQuoteCrumb,
      yourCoverCrumb: gState.yourCoverCrumb === 2 ? 1 : gState.yourCoverCrumb,
      paymentCrumb: 2,
    });

    // Get the GlobalPay HPP access token
    // @param riskModel the data entered on the form required to get the access token
    getGlobalPayHpp();
  }, []);

  return (
    <>
      {gState.paymentSuccessful === false && !gState.paymentTypeIsAnnual && (
        <DDGuarantee readyToRead={assumptionsAndDeclarationsAllChecked} />
      )}

      <section
        className="container container_narrow "
        hidden={!showPaymentWindow}
      >
        <div className="content_section mt-3  coverSummary">


          {gState.paymentTypeIsAnnual && (
            // Full payment
            <div hidden={!showPaymentWindow}>
              <div className="d-flex justify-content-center">
                <h4>
                  {"Card Details "}{" "}
                  <span className="blueFont"> Annual Payment</span>
                </h4>
              </div>
              <div id="card-payment-form" className="col-12">
                <div className="col-12">
                  <iframe id="payIframe" title="payIframe"></iframe>
                </div>
              </div>
              <div className="col-12">
                {error && (
                  <p>
                    {" "}
                    <small className="redFont mt-1 d-flex  justify-content-center">
                      {error}
                    </small>{" "}
                  </p>
                )}
              </div>
            </div>
          )}

          {gState.paymentTypeIsAnnual === false && (
            <>
              <section
                className="container container_narrow "
                hidden={!showPaymentWindow}
              >
                <div className=" mt-3 mb-4 ">
                  <div id="dd-payment-form">
                    <div className="col-12">
                      <DDForm formik={formik} />
                    </div>
                  </div>
                  {error && <small className="redFont mt-1 ">{error}</small>}
                </div>
              </section>
            </>
          )}
        </div>
      </section>

      <section className="container container_narrow ">
        <div className="row ">
          <div className="col-12 my-5">
            <div className="" hidden={!fromExternalLink}>
              <div >{amendDetails}</div>
            </div>
            <div className="" hidden={fromExternalLink}>
              <Link

                className="btn btn-wider btn-secondary  float-start mb-2"
                to={`/stepThree${search}`}
              >
                Back
              </Link>
            </div>
            <div className="">
              <button
                className="btn  btn-primary btn-wider float-end"
                hidden={!showPaymentWindow || gState.paymentTypeIsAnnual === true}
                onClick={async (e) => await handleBuyNowClick(e)}
              >
                Buy now
              </button>
              <button
                className="btn btn-primary btn-wider float-end"
                hidden={showPaymentWindow}
                onClick={async (e) => await handleClickPayNow(e)}
              >
                Pay now
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Payments;


function tryInceptPolicy(
  riskModelString,
  setPending,
  setRotate,
  history,
  setGState,
  gState,
  fromAggregator
) {
  window.scrollTo(0, 0);
  const promise = TransactorService.inceptPolicy(riskModelString, fromAggregator);
  promise.then(
    function (result) {
      if (result.success) {
        setRotate(false);
        setPending(false);

        setGState({
          ...gState,
          policyReference: result.value.policyReference,
          paymentSuccessful: true,
          paymentCrumb: 3,
          yourCoverCrumb: 0,
          yourDetailsCrumb: 0,
          yourQuoteCrumb: 0,
        });
        LoggingService.logInfo(`Inception succeeded for quote reference: ${gState.quoteReference}, converted to policy: ${result.value.policyReference} `);
        history.push(
          `/PolicyConfirmation?policyReference=${result.value.policyReference}`
        );
      } else {
        LoggingService.logError(`Inception failed : ${JSON.stringify(result)}, quote reference: ${gState.quoteReference} `);
        history.push("/InceptFailed");
        setPending(false);
        setRotate(false);
      }
    },
    function (err) {
      LoggingService.logError(`Inception failed : ${err}, quote reference: ${gState.quoteReference} `);
      history.push("/InceptFailed");
      setPending(false);
      setRotate(false);
      return;
    }
  );
}
