import cycleRescuePolicyWording1stMarch from "../../Documents/cycle-rescue-policy-wording-1st-march.pdf";
import Policy_Wording_Booklet_Web_March15 from "../../Documents/Policy_Wording_Booklet_Web_March15.pdf";
import velosurePolicyWording1stMarch from "../../Documents/VELO206 - Velosure Cycle Wording TM V14.pdf";
import cycleRescueIpid1stMarch from "../../Documents/velosure cycle rescue Policy Wording IPID.pdf";
import velosureIpid1stMarch from "../../Documents/velosure-insurance-product-information-document-1st-march.pdf";
// // import velosureIpid14July2020 from "../../Documents/velosure-insurance-product-information-document-14jul20-28feb21.pdf";
// // import velosurePolicyWording14July2020 from "../../Documents/velosure-policy-wording-14jul20-28feb21.pdf";
// // import cycleRescueIpid14July2020 from "../../Documents/cycle-rescue-insurance-product-information-document-14jul20-28feb21.pdf";
// // import cycleRescuePolicyWording14July2020 from "../../Documents/cycle-rescue-policy-wording-14jul20-28feb21.pdf";

// // import velosureIpid11September2018 from "../../Documents/velosure-insurance-product-information-document-11sep18-13jul20.pdf";
// // import velosurePolicyWording11September2018 from "../../Documents/velosure-policy-wording-11sep18-13jul20.pdf";
// // import cycleRescuePolicySummary11September2018 from "../../Documents/cycle-rescue-policy-summary-11sep18-13jul20.pdf";
// // import cycleRescuePolicyWording11September2018 from "../../Documents/cycle-rescue-policy-wording-11sep18-13jul20.pdf";

// // import velosureIpid13April2016 from "../../Documents/velosure_cycle_keyfacts-13apr16-10sep-18.pdf";
// // import velosurePolicyWording13April2016 from "../../Documents/cycle-rescue-policy-summary-13apr16-10sep-18.pdf";
// // import cycleRescuePolicySummary13April2016 from "../../Documents/velosure_cycle_wording-13apr16-10sep-18.pdf";
// // import cycleRescuePolicyWording13April2016 from "../../Documents/velosure_cycle_keyfacts-13apr16-10sep-18.pdf";

// // import velosureIpid16December2015 from "../../Documents/key-facts-16-dec-15-12-apr-16.pdf";
// // import velosurePolicyWording16December2015 from "../../Documents/policy-wording-16-dec-15-12-apr-16.pdf";
// // import cycleRescuePolicySummary16December2015 from "../../Documents/cycle-rescue-policy-summary-16-dec-15-12-apr-16.pdf";
// // import cycleRescuePolicyWording16December2015 from "../../Documents/cycle-rescue-policy-wording-16-dec-15-12-apr-16.pdf";

import VELO002CoreCycleInsurancePolicySchedule from "../../Documents/VELO205 - CORE Velosure Insurance Product Information Document (IPID) TM V4.pdf";
import VELO002CycleInsurancePolicySchedule from "../../Documents/VELO205 - Velosure Insurance Product Information Document (IPID) TM V4.pdf";

// //
import TOB from "../../Documents/VELO207 - Velosure Terms of Business TM.pdf";

const DocumentData = () => {
  return {
    DocumentList: [
      {
        id: 0,
        header: "Velosure policies purchased on or after 16th March 2023",
        docs: [
          {
            title: "Velosure Performance Insurance Product",
            key: 0,
            url: `${VELO002CycleInsurancePolicySchedule}`,
          },
          {
            title: "Velosure Core Insurance Product",
            key: 1,
            url: `${VELO002CoreCycleInsurancePolicySchedule}`,
          },
          {
            title: "Velosure Policy Wording",
            key: 2,
            url: `${Policy_Wording_Booklet_Web_March15}`,
          },
          {
            title: "Cycle Rescue Insurance Product",
            key: 3,
            url: `${cycleRescueIpid1stMarch}`,
          },
          {
            title: "Cycle Rescue Policy Wording",
            key: 4,
            url: `${cycleRescuePolicyWording1stMarch}`,
          },
          {
            title: "Terms of Business",
            key: 5,
            url: `${TOB}`,
          },
        ],
      },
      {
        id: 1,
        header:
          "Velosure policies purchased between 1st March 2021 and 15th March 2023",
        docs: [
          {
            title: "Velosure Insurance Product",
            key: 1,
            url: `${velosureIpid1stMarch}`,
          },
          {
            title: "Velosure Policy Wording",
            key: 2,
            url: `${velosurePolicyWording1stMarch}`,
          },
          {
            title: "Cycle Rescue Insurance Product",
            key: 3,
            url: `${cycleRescueIpid1stMarch}`,
          },
          {
            title: "Cycle Rescue Policy Wording",
            key: 4,
            url: `${cycleRescuePolicyWording1stMarch}`,
          },
        ],
      },
    ],
  };
};

export default DocumentData;
