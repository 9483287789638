import { useHistory } from 'react-router-dom';
const Redirector = () => {

    const history = useHistory();
    switch (window.location.pathname.toLowerCase()) {
        case '/sport-bike-cover': {
            return history.push('/types-we-cover#sport')
        }
        case '/electric-bicycle-insurance': {
            return history.push('/types-we-cover#electric')
        }
        case '/road-bike-insurance': {
            return history.push('/types-we-cover#road')
        }
        case '/mountain-bike-insurance': {
            return history.push('/types-we-cover#urban')
        }
        case '/mountain-bike-insurance': {
            return history.push('/types-we-cover#mountain')
        }
        case '/news':
        case '/news-post': {
            return history.push('/Pitstop')
        }
        default: {
            return history.push('/');
        }
    }
}

export default Redirector;

