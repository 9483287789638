import { useContext, useEffect, useState } from "react";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import useGlobalStateAdaptor from "../../CUSTOMHOOKS/useGlobalStateAdaptor";
import useFetch from "../../CUSTOMHOOKS/useFetch";
import YourQuote from "../StepThree/YourQuote";
import TopNavBlank from "../../Shared/TopNavBlank";
import RegularBanner from "../../MainSite/SHARED/RegularBanner";
import SummaryOfCover from "../StepThree/SummaryOfCover";
import { Link, useParams, useHistory } from "react-router-dom";
const QuoteSummary = () => {
  const history = useHistory();
  const params = useParams();
  const quoteId = params.id.slice(2);
  const [showQuoteDetails, setShowQuoteDetails] = useState(false);
  const [gState, setGState] = useContext(JourneyContext);
  const [vals, setVals] = useState(null);
  const [errored, setErrored] = useState(false);
  const [error, setError] = useState(false);
  const [incepted, setIncepted] = useState(false);
  const [expired, setExpired] = useState(false);
  const statefromExternalLink = useGlobalStateAdaptor(vals?.coreQuote, vals?.performanceQuote, vals?.selectedCoreScheme, quoteId);

  const [loading, setIsLoading] = useState(false);
  const [clickedButton, setClickedButton] = useState(false);
  const [canProceedToPayment, setCanProceedToPayment] = useState(true);
  const [validateNextButton, setValidateNextButton] = useState(false);
  const url = `${process.env.REACT_APP_AGGREGATOR_API_ENDPOINT}/AggregatorBackoffice/RetrieveQuote`;
  const options = {
    method: "POST",
    headers: {
      ApiKey: process.env.REACT_APP_AGGREGATOR_AUTH_KEY,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ QuoteId: quoteId }),
    mode: "cors",
  };
  const response = useFetch(url, options);

  useEffect(() => {
    if (response.error !== null) {
      setErrored(true);
      setError(
        <p>
          Something went wrong getting your quote, please check the link is correct
          and try again or contact our customer service team on{" "}
          <a href="tel:08000833035" target="_blank" rel="noreferrer">
            <strong>0800 083 3035 </strong>
          </a>
        </p>
      );
      return;
    }
    if (response.data === null || vals != null) {
      return;
    }
    if (response !== null) {
      if (response?.data?.coreQuote.inTransactor) {
        setIncepted(true);
        return;
      }
      if (response?.data?.coreQuote.expired) {
        setExpired(true);
        return;
      }

      setVals(response?.data);
    }
  }, [response]);

  useEffect(() => {
    if (!showQuoteDetails || errored) {
      setGState(statefromExternalLink);
      if (!statefromExternalLink.loading) {
        setShowQuoteDetails(true);
      }
    }
  }, [statefromExternalLink]);

  const onProceedToPayment = (e) => {
    e.preventDefault();
    setValidateNextButton(true);
    if (gState.hasPreviousClaim == null) {
      return;
    }


    if (!canProceedToPayment) {
      setClickedButton(true);
      const ele = document.getElementById('yourQuote');
      ele.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      window.scrollTo(0, 0);
      history.push(`/payment`)
    }
  }

  const amendDetails = (e) => {
    e.preventDefault();
    setGState({
      ...gState,
      yourCoverCrumb: 0,
      yourDetailsCrumb: 1,
      yourQuoteCrumb: 1,
      paymentCrumb: 0,
    });
    history.push(`/get-a-quote`)
  }

  return (
    <div className="container-fluid mb-5 oh">
      <TopNavBlank theme={"white"} />
      <RegularBanner
        headlineLine1={"Your bike"}
        headlineLine2={"insurance quote"}
        subheadlineLine1={"Tell us about you, your bike and cover you need."}
        subheadlineLine2={""}
        hasCTA={"false"}

        rotate={response.isPending}
      />
      <div className={(response.isPending || loading) ? "overlay" : "overlay_hidden"}>
        <h1 className="GettingQuoteOverlayH1">{loading ? 'Getting your updated quote...' : 'Getting your quote...'}</h1>
      </div>
      {!response.isPending && !loading && gState.performanceQuote != null && !incepted && !expired &&

        <YourQuote
          error={error}
          coreQuote={gState.coreQuote}
          setIsLoading={setIsLoading}
          showError={clickedButton}
          fromExternalLink={true}
          canProceedToPayment={setCanProceedToPayment}
          performanceQuote={gState.performanceQuote}
        />

      }
      {incepted && (
        <h4 id="quote-retrieval-incepted" className="container container_narrow content_section mt-5 mb-5">
          You have already purchased this policy. If you require further assistance,
          please contact our customer service team on  <a href="tel:08000833035" target="_blank" rel="noreferrer">
            <strong>0800 083 3035 </strong>
          </a>
        </h4>
      )}
      {expired && (
        <h4 id="quote-retrieval-expired" className="container container_narrow content_section mt-5 mb-5">
          this quote has expired. If you require further assistance,
          please contact our customer service team on  <a href="tel:08000833035" target="_blank" rel="noreferrer">
            <strong>0800 083 3035 </strong>
          </a>
        </h4>
      )}

      {errored && (
        <h4 id="quote-retrieval-expired" className="container container_narrow content_section mt-5 mb-5">
          {error}
        </h4>
      )}

      {showQuoteDetails && (
        <div id="aggregator-quote">
          <SummaryOfCover detailsText="Please ensure all of the details are correct. To amend any information click the 'Amend details' button below." fromExternalLink={true} validateNextButton={validateNextButton}/>
          <div className="container container_narrow">
          <div className="row my-5">
              <div className="col-12 mb-4 mt-3">
                <div
                  className="btn btn-wider btn-red float-start"
                  id="amend-details"
                  onClick={amendDetails}
                >
                  Amend details
                </div>
                <div className="col-12">
                  <div
                    id={`aggregator-proceed-to-payment#${gState.selectedCoreScheme ? "core" : "performance"}`}
                    className="btn btn-wider btn-green float-end"
                    onClick={onProceedToPayment}
                  >
                    Confirm details
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};
export default QuoteSummary;
