import roadBikeAngled from "../../Images/roadBikeAngled-w1200.jpg";

const WhyChooseSingleBikeCover = () => {
  return (
    <div className="container-fluid bg-lightblue pt-4 pb-5  pr oh">
      <div className="container">
        <div className="row  text-sm-start ">
          <div className="col-12">
            <h3 className="pt-4 pb-4 lufga-medium text-sm-start text-center">
              Why choose our single bike cover for{" "}
              <span className="blueFont">your bicycle</span>?
            </h3>
          </div>
          <div className="col-md-12 ">
            <div className="row text-start">
              <div id="left" className="col-12  offset-sm-0  col-lg-6 ">
                <p className="blueFont font-16">
                  Looking to protect the one cycle?
                </p>
                <p className="mb-5">
                  We understand how important your bike is to you, which is why
                  we include important features as standard.
                </p>
              </div>
            </div>
            <div className="row">
              <div
                id="left"
                className="col-12 offset-sm-0 col-md-6 mb-sm-5 mb-3 "
              >
                <div className="text-start ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="83"
                    height="82"
                    viewBox="0 0 83 82"
                  >
                    <defs>
                      <filter
                        id="Rectangle_1401"
                        x="4"
                        y="4"
                        width="79"
                        height="78"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <filter
                        id="Rectangle_1402"
                        x="0"
                        y="0"
                        width="78"
                        height="79"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur-2" />
                        <feFlood floodOpacity="0.031" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_794"
                          data-name="Rectangle 794"
                          width="41.942"
                          height="43.925"
                          fill="#fff"
                        />
                      </clipPath>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Rectangle_1401)"
                    >
                      <rect
                        id="Rectangle_1401-2"
                        data-name="Rectangle 1401"
                        width="67"
                        height="66"
                        rx="17"
                        transform="translate(10 7)"
                        fill="rgba(0,168,255,0.3)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Rectangle_1402)"
                    >
                      <rect
                        id="Rectangle_1402-2"
                        data-name="Rectangle 1402"
                        width="66"
                        height="67"
                        rx="17"
                        transform="translate(6 3)"
                        fill="#00a8ff"
                      />
                    </g>
                    <g
                      id="Group_2257"
                      data-name="Group 2257"
                      transform="translate(18.133 14.449)"
                    >
                      <g
                        id="Group_1759"
                        data-name="Group 1759"
                        transform="translate(0 0)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Path_2082"
                          data-name="Path 2082"
                          d="M10.1,13.249H41.81a1,1,0,0,0,0-1.992h-3.8L36.219,5.61C35.253,2.567,34.3.991,33.046.341s-2.483-.25-3.788.171a10.434,10.434,0,0,1-3.3.654A10.441,10.441,0,0,1,22.647.511c-1.3-.42-2.536-.816-3.786-.17S16.655,2.567,15.69,5.61L13.9,11.257H10.1a1,1,0,0,0,0,1.992m5.889-1.992,1.6-5.044c.758-2.392,1.474-3.734,2.187-4.1.515-.265,1.246-.03,2.264.3a12.252,12.252,0,0,0,3.915.75,12.264,12.264,0,0,0,3.924-.753c1.01-.324,1.741-.561,2.256-.294.713.368,1.429,1.71,2.187,4.1l1.6,5.044Z"
                          transform="translate(-4.983 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2083"
                          data-name="Path 2083"
                          d="M23.841,34.131a1,1,0,0,0-1,1,5.159,5.159,0,0,0,10.221,1h.822a5.159,5.159,0,0,0,10.221-1,1,1,0,0,0-1-1Zm18.116,1.992a3.167,3.167,0,0,1-6.012,0Zm-10.947,0a3.167,3.167,0,0,1-6.012,0Z"
                          transform="translate(-12.506 -18.684)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2084"
                          data-name="Path 2084"
                          d="M41.87,61.872a.987.987,0,0,0-.531-.546l-9.539-4.1,1.448-4.591a1,1,0,0,0-.95-1.295H9.643a1,1,0,0,0-.95,1.3l1.448,4.591L.6,61.326a1,1,0,0,0,.029,1.842.979.979,0,0,0,.758-.013L11.756,58.7a.992.992,0,0,0,.556-1.213L11,53.33h8.972v17.7a1,1,0,1,0,1.992,0V53.33h8.972l-1.31,4.154a.993.993,0,0,0,.556,1.214L40.55,63.155a1.019,1.019,0,0,0,1.311-.52.99.99,0,0,0,.01-.763"
                          transform="translate(0 -28.104)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>

                  <h4>
                    Theft & Accidental <br /> damage
                  </h4>
                  <p className="pr-1">
                    You are covered for theft inside and outside the property,
                    including aggravated theft whilst you are using the cycle.
                    Accidental damage to your cycle is also included on the same
                    terms within the geographical limits of the policy.
                  </p>
                </div>
                <div className="text-start ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="83"
                    height="82"
                    viewBox="0 0 83 82"
                  >
                    <defs>
                      <filter
                        id="Rectangle_1161"
                        x="5"
                        y="4"
                        width="78"
                        height="78"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <filter
                        id="Rectangle_1162"
                        x="0"
                        y="0"
                        width="78"
                        height="78"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur-2" />
                        <feFlood floodOpacity="0.031" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_795"
                          data-name="Rectangle 795"
                          width="40.76"
                          height="41.041"
                          fill="#fff"
                        />
                      </clipPath>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Rectangle_1161)"
                    >
                      <rect
                        id="Rectangle_1161-2"
                        data-name="Rectangle 1161"
                        width="66"
                        height="66"
                        rx="17"
                        transform="translate(11 7)"
                        fill="rgba(0,168,255,0.3)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Rectangle_1162)"
                    >
                      <rect
                        id="Rectangle_1162-2"
                        data-name="Rectangle 1162"
                        width="66"
                        height="66"
                        rx="17"
                        transform="translate(6 3)"
                        fill="#00a8ff"
                      />
                    </g>
                    <g
                      id="Group_2057"
                      data-name="Group 2057"
                      transform="translate(18.889 15.748)"
                    >
                      <g
                        id="Group_1760"
                        data-name="Group 1760"
                        transform="translate(0.001 0)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Path_2085"
                          data-name="Path 2085"
                          d="M39.824,26.206H27.8V16.34a.936.936,0,0,0-.936-.936h-1.5V12.9a.656.656,0,0,0-.655-.655H22.476V9.3a.636.636,0,0,0-.007-.071A3.712,3.712,0,0,0,23.638,7.67a.64.64,0,0,0,.076-.06C27.2,4.274,27.62,3.882,27.667,3.84l.035-.033A1.8,1.8,0,0,0,26.435.72h-1.8A.653.653,0,0,0,23.981,0h-7.2a.653.653,0,0,0-.651.72h-1.8a1.8,1.8,0,0,0-1.267,3.087l.035.033c.047.043.471.434,3.953,3.77a.66.66,0,0,0,.073.057,3.71,3.71,0,0,0,1.173,1.56.618.618,0,0,0-.007.07v2.945H16.059a.656.656,0,0,0-.655.655V15.4H13.9a.936.936,0,0,0-.936.936v5.545H.936A.936.936,0,0,0,0,22.821V40.1a.936.936,0,0,0,.936.936H39.824a.936.936,0,0,0,.936-.936V27.142a.936.936,0,0,0-.936-.936M26.926,2.522a.5.5,0,0,1-.147.354l.005.005c-.264.228-.911.844-2.743,2.6l.43-3.451h1.965a.492.492,0,0,1,.491.492m-13.092,0a.492.492,0,0,1,.491-.492H16.29l.428,3.449c-1.831-1.754-2.478-2.369-2.742-2.6l.006-.005a.5.5,0,0,1-.147-.354m3.687-1.212h5.718L22.586,6.56a2.369,2.369,0,0,1-1.548,1.975,2.1,2.1,0,0,1-1.318,0A2.366,2.366,0,0,1,18.173,6.56ZM19.6,9.86a3.369,3.369,0,0,0,1.57,0v2.382H19.6Zm-2.881,3.693h7.331V15.4H16.715ZM1.873,23.758h11.09V39.168H1.873Zm12.963-6.481h11.09V39.168H14.835ZM38.888,39.168H27.8V28.079h11.09Z"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2086"
                          data-name="Path 2086"
                          d="M52.53,61.1a1,1,0,0,0-.56-.165.632.632,0,0,0-.342.083l-1.557.943a.672.672,0,0,0-.342.625.833.833,0,0,0,.165.506.5.5,0,0,0,.412.224.521.521,0,0,0,.3-.094l.342-.212v6a.455.455,0,0,0,.271.418,1.5,1.5,0,0,0,1.3,0,.457.457,0,0,0,.272-.418v-7.5a.484.484,0,0,0-.254-.413"
                          transform="translate(-31.104 -38.112)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2087"
                          data-name="Path 2087"
                          d="M17.382,79.5H14.264V79.4a.918.918,0,0,1,.384-.628,8.391,8.391,0,0,1,.923-.7q.541-.358,1.075-.789a3.968,3.968,0,0,0,.915-1.063,2.582,2.582,0,0,0,.38-1.351,2.1,2.1,0,0,0-.831-1.748,3.055,3.055,0,0,0-1.934-.648,2.945,2.945,0,0,0-1.867.62,1.988,1.988,0,0,0-.794,1.649q0,.957.816.957a1.08,1.08,0,0,0,.694-.208A.612.612,0,0,0,14.288,75a.884.884,0,0,1,.252-.7.942.942,0,0,1,.646-.221.833.833,0,0,1,.911.848,1.326,1.326,0,0,1-.39.861,5.682,5.682,0,0,1-.953.849l-1.085.812a4.539,4.539,0,0,0-.917.915,1.713,1.713,0,0,0-.379,1.023V80.54a.48.48,0,0,0,.265.4,1.012,1.012,0,0,0,.539.166h4.208a.47.47,0,0,0,.39-.248,1,1,0,0,0,.166-.568.938.938,0,0,0-.167-.555.477.477,0,0,0-.393-.236"
                          transform="translate(-7.739 -45.327)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2088"
                          data-name="Path 2088"
                          d="M83.322,81.074A1.032,1.032,0,0,0,84,80.908a.622.622,0,0,0,.188-.49.5.5,0,0,1,.21-.454,1.248,1.248,0,0,1,.652-.132.749.749,0,0,1,.862.833,1.667,1.667,0,0,1-.076.549.535.535,0,0,1-.222.3.926.926,0,0,1-.278.116,1.623,1.623,0,0,1-.332.029c-.348,0-.523.235-.523.7s.175.7.522.7a1.481,1.481,0,0,1,.833.194.837.837,0,0,1,.287.744v.224a1.132,1.132,0,0,1-.239.815,1.158,1.158,0,0,1-.834.24q-1.038,0-1.037-.679a.537.537,0,0,0-.2-.475,1.427,1.427,0,0,0-.75-.135,1.2,1.2,0,0,0-.684.141.623.623,0,0,0-.189.527,2.085,2.085,0,0,0,.7,1.536,2.9,2.9,0,0,0,2.093.681,3.351,3.351,0,0,0,2.147-.646,2.4,2.4,0,0,0,.814-2v-.224a1.692,1.692,0,0,0-1.191-1.723,1.4,1.4,0,0,0,.667-.721,2.781,2.781,0,0,0,.242-1.186q0-2.138-2.621-2.139a2.8,2.8,0,0,0-2.027.639,1.994,1.994,0,0,0-.652,1.46.771.771,0,0,0,.172.592,1.353,1.353,0,0,0,.785.146"
                          transform="translate(-51.407 -48.935)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>

                  <h4>
                    Organised
                    <br />
                    races & events
                  </h4>
                  <p className="pr-3">
                    Sporting events such as sportives, road races and triathlons
                    are a great way to push your cycling performance to the next
                    level. However, all this fun can come to a screaming halt
                    when accidents occur, leading to bike damage (and your
                    chance at a podium finish!). If this should occur, at least
                    you can rely on Velosure to cover the costs.
                  </p>
                </div>
              </div>
              <div id="right" className="col-12 col-sm-6 mb-5 ">
                <div className="text-start ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="84"
                    height="83"
                    viewBox="0 0 84 83"
                  >
                    <defs>
                      <filter
                        id="Rectangle_1403"
                        x="5"
                        y="4"
                        width="79"
                        height="79"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <filter
                        id="Rectangle_1404"
                        x="0"
                        y="0"
                        width="79"
                        height="79"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur-2" />
                        <feFlood floodOpacity="0.031" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_798"
                          data-name="Rectangle 798"
                          width="43.531"
                          height="48.455"
                          fill="#fff"
                        />
                      </clipPath>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Rectangle_1403)"
                    >
                      <rect
                        id="Rectangle_1403-2"
                        data-name="Rectangle 1403"
                        width="67"
                        height="67"
                        rx="17"
                        transform="translate(11 7)"
                        fill="rgba(0,168,255,0.3)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Rectangle_1404)"
                    >
                      <rect
                        id="Rectangle_1404-2"
                        data-name="Rectangle 1404"
                        width="67"
                        height="67"
                        rx="17"
                        transform="translate(6 3)"
                        fill="#00a8ff"
                      />
                    </g>
                    <g
                      id="Group_2258"
                      data-name="Group 2258"
                      transform="translate(17.984 13.041)"
                    >
                      <g
                        id="Group_1763"
                        data-name="Group 1763"
                        transform="translate(0 0)"
                        clipPath="url(#clip-path9)"
                      >
                        <path
                          id="Path_2097"
                          data-name="Path 2097"
                          d="M43.5,11.556c-.018-2.933-.035-5.866-.035-8.8a1.423,1.423,0,0,0-2.508-.922c-1.6,1.886-4.99,3.1-8.628,3.1-4.9,0-8.364-2.058-9.258-4.091a1.424,1.424,0,0,0-2.606,0c-.894,2.033-4.361,4.091-9.258,4.091-3.639,0-7.025-1.219-8.628-3.1A1.423,1.423,0,0,0,.07,2.758c0,2.931-.018,5.865-.035,8.8S0,17.429,0,20.362c0,11.6,8.123,22.316,21.2,27.976a1.425,1.425,0,0,0,1.131,0c13.077-5.66,21.2-16.38,21.2-27.976,0-2.933-.018-5.869-.035-8.806M21.765,45.476C10.078,40.217,2.846,30.64,2.846,20.362c0-2.927.017-5.858.035-8.789Q2.9,8.649,2.91,5.727a16.671,16.671,0,0,0,8.295,2.061c4.445,0,8.377-1.481,10.56-3.838,2.183,2.357,6.116,3.838,10.561,3.838a16.669,16.669,0,0,0,8.294-2.061q.009,2.923.029,5.846c.017,2.931.035,5.862.035,8.789,0,10.278-7.232,19.855-18.92,25.114"
                          fill="#fff"
                        />
                        <path
                          id="Path_2098"
                          data-name="Path 2098"
                          d="M44.126,44.719H24.383a.707.707,0,0,0-.707.706v10.3a.707.707,0,0,0,.707.707H44.126a.707.707,0,0,0,.707-.707v-10.3a.707.707,0,0,0-.707-.706M25.089,46.132h2.157a2.38,2.38,0,0,1-2.157,2.119Zm0,8.884V52.9a2.381,2.381,0,0,1,2.157,2.119Zm18.33,0H41.262A2.38,2.38,0,0,1,43.419,52.9Zm0-3.542a3.8,3.8,0,0,0-3.583,3.542H28.672a3.8,3.8,0,0,0-3.583-3.542v-1.8a3.8,3.8,0,0,0,3.583-3.542H39.836a3.806,3.806,0,0,0,3.583,3.542Zm0-3.223a2.38,2.38,0,0,1-2.157-2.119h2.157Z"
                          transform="translate(-12.444 -23.504)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2099"
                          data-name="Path 2099"
                          d="M42.578,48.942c-1.69,0-3.066,1.728-3.066,3.852s1.375,3.852,3.066,3.852,3.065-1.728,3.065-3.852-1.375-3.852-3.065-3.852m0,6.29c-.9,0-1.652-1.117-1.652-2.438s.757-2.439,1.652-2.439,1.652,1.117,1.652,2.439-.757,2.438-1.652,2.438"
                          transform="translate(-20.767 -25.724)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2100"
                          data-name="Path 2100"
                          d="M40.528,37.556c.641-.491,1.8-.107,2.522.84a2.933,2.933,0,0,1,.528,1.1h1.3a4.092,4.092,0,0,0-.811-1.872c-1.166-1.521-3.1-2-4.31-1.076a2.761,2.761,0,0,0-.816,2.949h1.339a1.654,1.654,0,0,1,.252-1.937"
                          transform="translate(-20.404 -18.959)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2101"
                          data-name="Path 2101"
                          d="M28.907,31.97l8-6.127a3.434,3.434,0,0,0,4.511.571L42.4,27.7a3.433,3.433,0,0,0-.622,4.5l-.095.073h2.1l1.846-1.415a.638.638,0,0,0,.118-.894L40.094,22.6a.638.638,0,0,0-.894-.119l-12.792,9.8h2.679a3.44,3.44,0,0,0-.181-.308m14.276-3.248,1.163,1.517L42.8,31.423a2.147,2.147,0,0,1,.382-2.7m-3.713-4.846,1.163,1.518a2.148,2.148,0,0,1-2.708-.334Z"
                          transform="translate(-13.88 -11.745)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>

                  <h4>
                    Public <br />
                    liability
                  </h4>
                  <p className="pr-3">
                    As cyclists, we need to be paying attention at all times,
                    but sadly this does not always prevent accidents from
                    occurring. Accidents can happen to anyone but may
                    unfortunately leave the liable party with a large bill to
                    repay. We cover you for public liability up to £2 million.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  end row */}

        <div className="col-12 col-md-6 d-block d-md-none oh singleBikeImageContainer">
          <img
            src={roadBikeAngled}
            className="whyChooseSingleCoverImage"
            alt="Single bike cover"
          />
        </div>
      </div>
      <img
        src={roadBikeAngled}
        className="whyChooseSingleCoverImage d-none d-md-block"
        alt="Why choose single bike insurance"
      />
    </div>
  );
};

export default WhyChooseSingleBikeCover;
