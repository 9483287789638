import { createContext, useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
export const initialJourneyState = {
  combinedHomeValue: 0,
  awayValue: 0,
  isElectric: null,
  accessoryCover: true,
  worldwideCover: false,
  sportsCover: false,
  personalAccident: false,
  bikes: [],
  hasPreviousClaim: null,
  title: "",
  titleId: null,
  forename: "",
  surname: "",
  dob: "",
  dob_d: "",
  dob_m: "",
  dob_y: "",
  telephoneNo: "",
  email: "",
  houseNo: "",
  houseName: "",
  houseSubName: "",
  organisation: "",
  postcode: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  addressLine4: "",
  keptAtHome: true,
  coverStartDate: null,
  storageLocation: 1,
  assumptionsTicked: false,
  annualGrossPremium: 0,
  basePremium: 0,
  commission: 0,
  declineReason: null,
  instalmentsApr: 0,
  instalmentsFirstPayment: 0,
  instalmentsGrossPremium: 0,
  instalmentsInterestPc: 0,
  instalmentsServiceCharge: 0,
  instalmentsSubsequentPayments: 0,
  ipt: 0,
  netPremium: 0,
  quoteReference: "",
  referralReason: null,
  schemeId: "",
  schemeTable:0,
  yourCoverCrumb: 2,
  yourDetailsCrumb: 0,
  yourQuoteCrumb: 0,
  paymentCrumb: 0,
  showAddressPreview: false,
  currentlyAddingABike: true,
  currentlyEditingABike: false,
  AboutYourBikeFormIsValid: false,
  AboutYouFormIsValid: false,
  hideAddressForm: false,
  paymentTypeIsAnnual: false,
  accountName: "",
  accountNumber: "",
  accountSortCode: "",
  accountBankName: "",
  paymentSuccessful: false,
  marketingReference: null,
  sourceOfBusinessId: null,
  customSource: false,
  resetAwayValue: true,
  DDFormIsValid: false,
  optIn: false,
  preferredMethodOfContact: "",
  recieveByEmailOnly: false,
  preferTelephone: false,
  preferEmail: false,
  adminPhone: false,
  adminEmail: false,
  thirdPartyPhone: false,
  thirdPartyEmail:false,
  clickedPayNow:false,
  coreQuote:null,
  performanceQuote: null,
  initQuote:null,
  selectedCoreScheme: null,
  aggregatorQuoteId:null,
  validatedRules:null,
};

export const JourneyContext = createContext();

const JourneyStore = ({ children }) => {
  const sob = sessionStorage.getItem("sourceOfBusinessId");
  if (sob?.length > 0 && sob !== "null" && sob !== null) {
    initialJourneyState.sourceOfBusinessId = sob;
    initialJourneyState.marketingReference = `Retailer - ${sob}`;
    initialJourneyState.customSource = false;
    initialJourneyState.disableSOB = true;
  }
  const [state, setState] = useState(initialJourneyState);
  useEffect(() => {
    const context = JSON.parse(sessionStorage.getItem("context"));
    if (context) {
      setState(context);
    } else {
      setState(initialJourneyState);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    const context = JSON.parse(sessionStorage.getItem("context"));
    if (!!!context) {
      setState(initialJourneyState);
      sessionStorage.removeItem("context");
      sessionStorage.setItem("context", JSON.stringify(state));
    } else {
      sessionStorage.setItem("context", JSON.stringify(state));
    }
  }, [state]);
  return (
    <JourneyContext.Provider value={[state, setState]}>
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return `Are you sure you want to leave this page? Doing so may cause you to lose all your progress.`;
          }

          return true;
        }}
      />
      {children}
    </JourneyContext.Provider>
  );
};

export default JourneyStore;
