import "./../CSS/Offers.css";
import OffersHelper from "./OffersHelper";

const OffersForHeroBanner = () => {
  return (
    <>
      {OffersHelper.isOfferActive() && (

        <div className="offerCogPosition faqBlock offerCog" dangerouslySetInnerHTML={{ __html: sessionStorage.getItem('offerIcon') }} onClick={() => {var ele = document.getElementById("footer");
        ele.scrollIntoView({ behavior: "smooth", block: "center" });}}>


        </div>
      )}

    </>
  );
};

export default OffersForHeroBanner;
