
import { useLocation, useHistory } from "react-router-dom";
import helper from "../HELPERS/ModelAdaptorHelper";
const VeloAPIService = {
    CheckAndApplyOfferFull(code = "") {
        const { search } = useLocation();
        const history = useHistory();

        const query = new URLSearchParams(search);
        const options = {
            method: "GET",
            headers: {
                "X-API-KEY": process.env.REACT_APP_VELOSURE_API_KEY,
                "content-type": "application/json",
            },
        };
        fetch(`${process.env.REACT_APP_VELOSURE_API_URL}/api/Offers/GetCurrentOffer?code=${code}`, options)
            .then((response) => response.json())
            .then((data) => {
                if (data?.status == 404) {
                    sessionStorage.setItem("checkedForOffer", 'true');
                    sessionStorage.setItem("FoundOffer", 'false');
                    sessionStorage.setItem("sourceOfBusinessId", code);
                } else {
                    sessionStorage.setItem("checkedForOffer", 'true');
                    sessionStorage.setItem("OfferActive", 'true');
                    sessionStorage.setItem("OfferName", data.name);
                    sessionStorage.setItem("OfferRules", data.offerRules)
                    sessionStorage.setItem("sourceOfBusinessId", data.defaultCode);
                    sessionStorage.setItem("offerFooterText", data.footerText);
                    sessionStorage.setItem("jouneryHeaderText", data.jouneryHeaderText);
                    sessionStorage.setItem("offerIcon", data.offerIcon);
                }
            }).then(() => {
                query.delete('sourcebusid');
                history.replace({
                    search: query.toString(),
                });
                window.location.reload(false);
            });
    },
    CheckAndApplyOffer() {
        const options = {
            method: "GET",
            headers: {
                "X-API-KEY": process.env.REACT_APP_VELOSURE_API_KEY,
                "content-type": "application/json",
            },
        };

        fetch(`${process.env.REACT_APP_VELOSURE_API_URL}/api/Offers/GetCurrentOffer`, options)
            .then((response) => response.json())
            .then((data) => {
                if (data?.status == 404) {
                    sessionStorage.setItem("checkedForOffer", 'true');
                    sessionStorage.setItem("FoundOffer", 'false');
                } else {
                    sessionStorage.setItem("checkedForOffer", 'true');
                    sessionStorage.setItem("OfferActive", 'true');
                    sessionStorage.setItem("OfferName", data.name);
                    sessionStorage.setItem("OfferRules", data.offerRules)
                    sessionStorage.setItem("sourceOfBusinessId", data.defaultCode);
                    sessionStorage.setItem("offerFooterText", data.footerText);
                    sessionStorage.setItem("jouneryHeaderText", data.jouneryHeaderText);
                    sessionStorage.setItem("offerIcon", data.offerIcon);
                }
            }).then(() => {
                window.location.reload(false);
            });
    },
    async AddTransactorNotes(request) {
        const options = {
            method: "POST",
            headers: {
                "X-API-KEY": process.env.REACT_APP_VELOSURE_API_KEY,
                "content-type": "application/json",
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${process.env.REACT_APP_VELOSURE_API_URL}/api/TransactorNotes/AddNote`, options);
        return await response.json();
    }
}

export default VeloAPIService;