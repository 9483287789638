import TopNavBar from "../../Shared/TopNavBar";
import RegularBanner from "../SHARED/RegularBanner";
import WhatOurCustomersSay from "../SHARED/WhatOurCustomersSay";
import ProtectYourBike from "../SHARED/ProtectYourBike";
import FAQSection from "./FAQSection";
import CantSeeAnswer from "./CantSeeAnswer";
import { useEffect } from "react";
import { seoTags } from "../../Shared/SeoEdit";
import { Helmet } from "react-helmet";

const FAQ = () => {
  useEffect(() => {
    seoTags(
      "FAQ",
      "Frequently asked questions about the most commonly used cycle insurance terms. ",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);
  return (
    <div className=" oh">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.velosure.co.uk/bicycle-insurance-faqs"
        />
      </Helmet>
      <TopNavBar theme={"white"} />
      <RegularBanner
        headlineLine1={"Frequently"}
        headlineLine2={"asked questions"}
        subheadlineLine1={
          "All the information you need to decide if our cover meets your needs"
        }
        subheadlineLine2={""}
        hasCTA={"false"}
        CTAText={"Get a quote"}
        requiresSpacer={true}
      />
      <FAQSection />
      <CantSeeAnswer />
      <WhatOurCustomersSay />
      <ProtectYourBike variant={1} />
    </div>
  );
};

export default FAQ;
