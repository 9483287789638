import TopNavBar from "../../Shared/TopNavBar";

import WhatOurCustomersSay from "../SHARED/WhatOurCustomersSay";
import ProtectYourBike from "../SHARED/ProtectYourBike";
import { useEffect } from "react";
import { seoTags } from "../../Shared/SeoEdit";
import RegularBanner from "../SHARED/RegularBanner";
import VelosureCore from "./VelosureCore";
import VelosurePerformance from "./VelosurePerformance";
import CorePerformanceComparison from "./CorePerformanceComparison";
import CorePerformanceIntro from "./CorePerformanceIntro";

const OurCoverLevels = () => {
  useEffect(() => {
    seoTags(
      "Cover levels",
      "Comprehensive bicycle cover, created by cyclists, for cyclists. Our cycle insurance keep syou covered when you need it most.",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);
  return (
    <div className="container-fluid">
      <TopNavBar theme={"white"} />
      <RegularBanner
        headlineLine1={"Our cover"}
        headlineLine2={"levels"}
        subheadlineLine1={
          ""
        }
        subheadlineLine2={""}
        hasCTA={"true"}
        CTAText={"Get a quote"}
        requiresSpacer={false}
      />
      <CorePerformanceIntro/>
      <VelosureCore />
      <VelosurePerformance />
      <CorePerformanceComparison />
      <WhatOurCustomersSay />
      <ProtectYourBike variant={1} />
    </div>
  );
};

export default OurCoverLevels;
