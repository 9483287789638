const BannerData = () => {
  return [
         {
        id: 1,
        name: "120x240",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/120x240.png",
      },
      {
        id: 2,
        name: "120x600",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/120x600.png",
      },
      {
        id: 3,
        name: "125x125",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/125x125.png",
      },
      {
        id: 4,
        name: "160x600",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/160x600.png",
      },
      {
        id: 5,
        name: "180x150",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/180x150.png",
      },
      {
        id: 6,
        name: "240x400",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/240x400.png",
      },
      {
        id: 7,
        name: "250x250",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/250x250.png",
      },
      {
        id: 8,
        name: "300x100",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/300x100.png",
      },
      {
        id: 9,
        name: "300x250",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/300x250.png",
      },
      {
        id: 10,
        name: "300x600",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/300x600.png",
      },
      {
        id: 11,
        name: "336x280",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/336x280.png",
      },
      {
        id: 12,
        name: "720x300",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/720x300.png",
      },
      {
        id: 13,
        name: "728x90",
        url: "https://connexus-cdn.azureedge.net/velo-affiliate-banners/728x90.png",
      },
    ]
  }


export default BannerData;
