import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import JourneyStore from "./CONTEXT/JourneyStore";

import AboutUs from "./MainSite/ABOUT_US/aboutUs";
import BikesWeCover from "./MainSite/BIKES_WE_COVER/bikesWeCover";
import ContactUs from "./MainSite/CONTACT_US/contactUs";
import CoverComparison from "./MainSite/COVER_COMPARISON/CoverComparison";
import FAQ from "./MainSite/FAQ/faq";
import Home from "./MainSite/HOME/Home";
import MakeAComplaint from "./MainSite/MAKE_A_COMPLAINT/makeAComplaint";
import OurCoverLevels from "./MainSite/OUR_COVER_LEVELS/OurCoverLevels";
import FullBlog from "./MainSite/PITSTOP/FullBlog";
import Pitstop from "./MainSite/PITSTOP/pitstop";
import PolicyInformation from "./MainSite/POLICY_INFORMATION/policyInformation";
import ReferAFriend from "./MainSite/REFER_A_FRIEND/ReferAFriend";
import RetailerAffiliate from "./MainSite/RETAILER_AFFILIATE/RetailerAffiliate";
import Reviews from "./MainSite/REVIEWS/Reviews";
import SecurityRequirements from "./MainSite/SECURITY_REQUIREMENTS/securityRequirements";
import SubmitAClaim from "./MainSite/SUBMIT_A_CLAIM/SubmitAClaim";
// import BrandAmbassador from "./MainSite/BRAND_AMBASSADOR/BrandAmbassador";
import "./CSS/site.css";
import Payment from "./Journey/Aggregator/Payment";
import QuoteSummary from "./Journey/Aggregator/QuoteSummary";
import InceptFailed from "./Journey/InceptFailed";
import PaymentError from "./Journey/PaymentError";
import PolicyConfirmation from "./Journey/PolicyConfirmation";
import QuoteReferred from "./Journey/QuoteReferred";
import QRLandingPage from "./Journey/QuoteRetrieval/QRLandingPage";
import QRPayment from "./Journey/QuoteRetrieval/QRPayment";
import QRQuoteSummary from "./Journey/QuoteRetrieval/QRQuoteSummary";
import StepFour from "./Journey/StepFour/StepFour";
import StepOne from "./Journey/StepOne/StepOne";
import StepThree from "./Journey/StepThree/StepThree";
import StepTwo from "./Journey/StepTwo/StepTwo";
import AccessoryCover from "./MainSite/ACCESSORY_COVER/accessoryCover";
import CookiePolicy from "./MainSite/COOKIE_POLICY/CookiePolicy";
import EBikeCover from "./MainSite/OUR_COVER/EBikeCover";
import MultiBikeCover from "./MainSite/OUR_COVER/MultiBikeCover";
import SingleBikeCover from "./MainSite/OUR_COVER/SingleBikeCover";
import Privacy from "./MainSite/PRIVACY/Privacy";
import TermsAndConditions from "./MainSite/TERMS_AND_CONDITIONS/TermsAndConditions";
import Travel from "./MainSite/TRAVEL/Travel";
import VulnerabilityPolicy from "./MainSite/VULNERABILITY/VulnerabilityPolicy";
import Redirector from "./redirector";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      {/* =======================================JOURNEY ROUTES =============================*/}

      <Route exact path="/get-a-quote">
        {/* =====  each route must be wrapped - not possible to wrap the 4 journey routes Switch only has routes as children*/}
        <JourneyStore>
          <StepOne />
        </JourneyStore>
      </Route>
      <Route exact path="/StepTwo">
        <JourneyStore>
          <StepTwo />
        </JourneyStore>
      </Route>
      <Route exact path="/StepThree">
        <JourneyStore>
          <StepThree />
        </JourneyStore>
      </Route>
      <Route exact path="/StepFour">
        <JourneyStore>
          <StepFour />
        </JourneyStore>
      </Route>
      <Route exact path="/PolicyConfirmation">
        <JourneyStore>
          <PolicyConfirmation />
        </JourneyStore>
      </Route>
      <Route exact path="/QuoteReferred">
        <JourneyStore>
          <QuoteReferred />
        </JourneyStore>
      </Route>
      <Route exact path="/PaymentError">
        <JourneyStore>
          <PaymentError />
        </JourneyStore>
      </Route>
      <Route exact path="/InceptFailed">
        <InceptFailed />
      </Route>

      {/* =======================================Aggregator ROUTES =============================*/}
      <Route exact path="/aggregator/:id">
        <JourneyStore>
          <QuoteSummary />
        </JourneyStore>
      </Route>

      <Route exact path="/payment">
        <JourneyStore>
          <Payment />
        </JourneyStore>
      </Route>
      {/* =======================================QR ROUTES =============================*/}

      <Route exact path="/QuoteRetrieval">
        <JourneyStore>
          <QRLandingPage />
        </JourneyStore>
      </Route>
      <Route exact path="/QuoteRetrievalSummary">
        <JourneyStore>
          <QRQuoteSummary />
        </JourneyStore>
      </Route>

      <Route exact path="/QuoteRetrievalPayment">
        <JourneyStore>
          <QRPayment />
        </JourneyStore>
      </Route>

      {/* =======================================MAIN SITE ROUTES =============================*/}
      <Route exact path="/about">
        <AboutUs />
      </Route>
      <Route exact path="/contact">
        <ContactUs />
      </Route>
      <Route exact path="/SubmitAClaim">
        <SubmitAClaim />
      </Route>
      <Route exact path="/MakeAComplaint">
        <MakeAComplaint />
      </Route>
      <Route exact path="/ReferAFriend">
        <ReferAFriend />
      </Route>
      {/* <Route exact path="/BrandAmbassador">
        <BrandAmbassador />
      </Route> */}
      <Route exact path="/policy-information">
        <PolicyInformation />
      </Route>
      <Route exact path="/security-requirements">
        <SecurityRequirements />
      </Route>
      <Route exact path="/bicycle-insurance-faqs">
        <FAQ />
      </Route>
      <Route exact path="/OurCoverLevels">
        <OurCoverLevels />
      </Route>
      <Route exact path="/Reviews">
        <Reviews />
      </Route>
      <Route exact path="/bike-insurance-comparison-chart">
        <CoverComparison />
      </Route>
      <Route exact path="/types-we-cover">
        <BikesWeCover />
      </Route>
      <Route exact path="/bicycle-travel-insurance">
        <Travel />
      </Route>
      <Route exact path="/Pitstop">
        <Pitstop />
      </Route>
      <Route path="/FullBlog/:id">
        <FullBlog />
      </Route>

      <Route exact path="/affiliate-scheme">
        <RetailerAffiliate />
      </Route>

      <Route exact path="/single-bike-insurance">
        <SingleBikeCover />
      </Route>
      <Route exact path="/multi-bike-insurance">
        <MultiBikeCover />
      </Route>
      <Route exact path="/electric-bicycle-insurance">
        <EBikeCover />
      </Route>
      <Route exact path="/accessories-we-cover">
        <AccessoryCover />
      </Route>
      <Route exact path="/our-cover-levels">
        <OurCoverLevels />
      </Route>
      <Route exact path="/Privacy">
        <Privacy />
      </Route>
      <Route exact path="/Cookies">
        <CookiePolicy />
      </Route>
      <Route exact path="/Terms">
        <TermsAndConditions />
      </Route>
      <Route exact path="/VulnerabilityPolicy">
        <VulnerabilityPolicy />
      </Route>
      <Route component={Redirector} />
    </Switch>
  );
};
export default Routes;
