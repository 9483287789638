import TopNavBar from '../../Shared/TopNavBar';
import OurSingleBikeCover from './OurSingleBikeCover';
import WhyChooseSingleBikeCover from './WhyChooseSingleBikeCover';
import HeroBanner from '../SHARED/HeroBanner';
import WhatOurCustomersSay  from '../SHARED/WhatOurCustomersSay';
import ProtectYourBike from '../SHARED/ProtectYourBike';
import { useEffect } from 'react';
import { seoTags } from "../../Shared/SeoEdit";
import { Helmet } from 'react-helmet';

const SingleBikeCover = () => {


    useEffect(() => {
        seoTags("Single bike cover","Comprehensive single bicycle cover, created by cyclists, for cyclists. Our cycle insurance keep syou covered when you need it most.","");
        return () => {
          seoTags("velosure ", "", "");
         
        };
      }, []);
    return ( 
      <div className="container-fluid">
         <Helmet>
         <link rel="canonical" href="https://www.velosure.co.uk/single-bike-insurance" />
       </Helmet>
            <TopNavBar theme={"transparent"}/>
            <HeroBanner 
                heading1={"Single bike cover"}
                heading2={""}
                subHeading={"Whether you use your bike for commuting, shopping or road racing, comprehensive protection at great value."}
                image={1}
                />
            <OurSingleBikeCover/>
            <WhyChooseSingleBikeCover/>
            <WhatOurCustomersSay/>
            <ProtectYourBike variant={1}/>
        </div>
     );
}
 
export default SingleBikeCover;