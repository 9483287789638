import TopNavBar from "../../Shared/TopNavBar";
import RegularBanner from "../SHARED/RegularBanner";
import WhatOurCustomersSay from "../SHARED/WhatOurCustomersSay";
import ProtectYourBike from "../SHARED/ProtectYourBike";
import IntroBlock from "./IntroBlock";
import HowItStarted from "./HowItStarted";
import WhyInsureWithVelosure from "./WhyInsureWithVelosure";
import NeedAQuote from "./NeedAQuote";
import { useEffect } from "react";
import { seoTags } from "../../Shared/SeoEdit";
import { Helmet } from "react-helmet";


const AboutUs = () => {
  useEffect(() => {
    seoTags(
      "About Us",
      "11 years since inception, we're still providing the most comprehensive bicycle insurance to amateur and professional cyclists. ",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);
  return (
    <div className="container-fluid">
       <Helmet>
        <link rel="canonical" href="https://www.velosure.co.uk/about" />
      </Helmet>
      <TopNavBar theme={"white"} />
      <RegularBanner
        headlineLine1={"About us"}
        headlineLine2={""}
        subheadlineLine1={
          "Offering the best value product on the market, while providing you with a cover that fully meets your needs.  "
        }
        subheadlineLine2={""}
        hasCTA={"true"}
        CTAText={"Get a quote"}
        requiresSpacer = {true}
      />
      <IntroBlock />
      <HowItStarted />
      <WhyInsureWithVelosure />
      <NeedAQuote />
      <WhatOurCustomersSay />
      <ProtectYourBike variant={1} />
    </div>
  );
};

export default AboutUs;
