import IndividualBikeRow from "./IndividualBikeRow";
import ModelAdaptorHelper from "../../HELPERS/ModelAdaptorHelper";

const BikeListWithLockInfo = ({ bikes, updateLockChecked }) => {
  return (
    <div id='lockChecked'>
      <ul className="lufga-regular">
        {bikes.map((bike) => (
          <li key={bike.id}>
            <IndividualBikeRow
              bikeMake={bike.make}
              bikeModel={bike.model}
              lockName={ModelAdaptorHelper.getLockName(bike.value)}
              bikeId={bike.id}
              lockChecked={bike.lockChecked}
              updateLockChecked={updateLockChecked}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BikeListWithLockInfo;
