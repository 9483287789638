import { useContext, useState, useEffect } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import IndividualBike from "./IndividualBike";

const BikeList = ({validateNextButton  }) => {
  const [gState, setGState] = useContext(JourneyContext);

  return gState.bikes.map((bike, index) => (

    <IndividualBike bike={bike} key={index} validateNextButton={validateNextButton}/>
  ));
};

export default BikeList;
