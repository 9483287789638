import AboutYourBike from "./AboutYourBike";
import BikeList from "./BikeList";
import BikeSecurity from "./BikeSecurity";
import Breadcrumbs from "../../Shared/Breadcrumbs";
import RegularBanner from "../../MainSite/SHARED/RegularBanner";
import { useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { JourneyContext, initialJourneyState } from "../../CONTEXT/JourneyStore";
import PreviousClaims from "./PreviousClaims";
import AwayValue from "./AwayValue";
import { useState } from "react";
import ModelAdaptorHelper from "../../HELPERS/ModelAdaptorHelper";
import { seoTags } from "../../Shared/SeoEdit";
import { Helmet } from "react-helmet";
import TopNavBlank from "../../Shared/TopNavBlank";
import { Engine, Rule, Fact } from 'json-rules-engine';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
const StepOne = () => {
  const [gState, setGState] = useContext(JourneyContext);
  const [opened, { close, open }] = useDisclosure(false);
  const { search } = useLocation();
  const [validateNextButton, setValidateNextButton] = useState(false);
  const [locationInvalid, setLocationInvalid] = useState(false);
  const [searched, setSearched] = useState(false);
  const sob = sessionStorage.getItem("sourceOfBusinessId");
  const history = useHistory();
  const params = new URLSearchParams(search);
  const policyReference = params.get('policyReference');
  const offerRules = sessionStorage.getItem("OfferRules");
  if (policyReference != null) {
    sessionStorage.removeItem("context");
    setGState(initialJourneyState)
    sessionStorage.removeItem("context");
    history.push(`/get-a-quote`);
  }

  useEffect(() => {
    seoTags(
      "Get-a-quote",
      "Receive a Competitive Bike Insurance Quote from Velosure Cycle Insurance | Quick, Comprehensive Bike Insurance Quote to Ensure You're Covered Where You Need it Most.",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);


  useEffect(() => {
    if (locationInvalid || gState.hasPreviousClaim) {
      setGState({
        ...gState,
        paymentCrumb: 0,
        generateQuote: true,
        yourQuoteCrumb: 0,
        yourCoverCrumb: 2,
        yourDetailsCrumb: 0
      });
    }
  });

  useEffect(() => {
    if (gState.storageLocation === 4) {
      // if 'other' ( value 4) chosen, the quote cannot move forward. Set the form to invalid so that the next button dos not show
      setLocationInvalid(true);
    } else {
      setLocationInvalid(false);
    }
  }, [gState.storageLocation]);

  const handleNextButton = async (e) => {
    e.preventDefault();
    setValidateNextButton(true);
    setTimeout(function () {
      setValidateNextButton(false);
    }, 1000);
    if (gState.currentlyAddingABike) {
      var ele = document.getElementById("Add-this-bike");
      ele.scrollIntoView({ behavior: "smooth", block: "center" });

      return;
    }
    if (gState.currentlyEditingABike) {
      var ele = document.getElementById("edit-this-bike");
      ele.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }
    if (
      gState.bikes.length > 1 &&
      (gState.awayValue < 500 ||
        gState.awayValue > ModelAdaptorHelper.getHomeValue(gState.bikes))
    ) {
      var ele = document.getElementById("awayValue");
      ele.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    if (gState.hasPreviousClaim == null) {
      var ele = document.getElementById("has-previous-claim");
      ele.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    if (offerRules != null && offerRules?.length > 1 && offerRules != 'null' && !searched) {
      let engine = new Engine();
      var rule = JSON.parse(offerRules)
      engine.addRule(new Rule({
        result: true,
        conditions: {
          all: [
            rule
          ]
        },
      }))

      engine.run(gState).then(e => {
        if (e.results.some(s => s.result)) {
          setSearched(true);
          if (gState.validatedRules === true) {
            handleNextPage();
            return;
          }
          setGState({
            ...gState,
            marketingReference: null,
            sourceOfBusinessId: null,
            customSource: false,
            disableSOB: false,
            validatedRules: true
          });
          handleNextPage();
          return;
        } else {
          handleSetSob();
          return;
        }
      })
    }
    else if (sob != null && !searched && gState.sourceOfBusinessId === null) {
      handleSetSob();
      return;
    } else if (sob != null ){
      handleNextPage();
      return;
    }
      else {
      setGState({
        ...gState,
        sourceOfBusinessId: null,
        customSource: false,
        disableSOB: false,
        validatedRules: true
      });
      handleNextPage();
      return;
    }

  };
  function handleSetSob() {
    setSearched(true);
    setGState({
      ...gState,
      sourceOfBusinessId: sob,
      customSource: false,
      disableSOB: true,
      validatedRules: false,
      marketingReference: "",
    });
    handleNextPage();
  }

  function handleNextPage() {
    history.push(`/stepTwo${search}`);
    setValidateNextButton(false);
    window.scrollTo(0, 0);
  }

  return (

    <div className="pr oh blueBorderBott">

      <Helmet>
        <link rel="canonical" href="https://www.velosure.co.uk/get-a-quote" />
      </Helmet>
      <TopNavBlank theme={"white"} />
      <RegularBanner
        headlineLine1={"Your bike "}
        headlineLine2={"insurance quote"}
        subheadlineLine1={"Tell us about you, your bike and cover you need."}
        subheadlineLine2={""}
        hasCTA={"false"}
        CTAText={"Get a quote"}
      />

      <Breadcrumbs currentPage="yourCover" navigationAction={handleNextButton} />

      <BikeList validateNextButton={validateNextButton} />

      <AboutYourBike validateNextButton={validateNextButton} opened={opened} close={close} open={open} />


      {gState.bikes.length > 1 && (
        <AwayValue validateNextButton={validateNextButton} />
      )}

      <BikeSecurity />

      <PreviousClaims validateNextButton={validateNextButton} />

      <div className="container container_narrow ">
        <div className="row">
          <div className="col-12 mb-4 mt-5">
            {locationInvalid === false && gState.hasPreviousClaim !== null && (
              <div
                id="move-to-step-two"
                className="btn btn-wider btn-green float-end mb-2 lufga-medium"
                onClick={async (e) => {
                  await handleNextButton(e);
                }}
              >
                Next step
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;


