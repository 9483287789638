import { useLocation } from "react-router-dom";
import VeloAPIService from "../Services/VeloAPIService";
import LoggingService from "../Services/LoggingService";
export const SOBQueryParamHandler = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const sobFromCache = sessionStorage.getItem("sourceOfBusinessId");
    const checkedForOffer = sessionStorage.getItem("checkedForOffer");
    const callCentreUserID = sessionStorage.getItem("CallCentreUserID");
    if (callCentreUserID == null && query.get("CallCentreUserID") != null) {
        sessionStorage.setItem("CallCentreUserID", query.get("CallCentreUserID"));
    }

    if (window.location.pathname.toLowerCase().includes('aggregator') || sobFromCache != null || window.location.pathname.toLowerCase().includes('quoteretrieval')) {
        return;
    }

    if (checkedForOffer === null) {
        // LoggingService.logInfo("Entered site on : " + window.location.href);
        VeloAPIService.CheckAndApplyOfferFull(query.get("sourcebusid") ?? null);
    }
}

export default SOBQueryParamHandler;