//Service for communicating with the Transactor v6 API wrapper

import RestApiCommBaseService from "./RestApiCommBaseService";

const TransactorService = {
  // Get all the relevant Sources of Business as an array
  // Optional campaign id for filtering related SOBs
  fetchAllSourcesOfBusiness: (campaignId = "") => {
    const opts = {
      method: "GET",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
      },
    };

    // if campaign ID provided, construct a query string
    let campaignString = "";
    if (campaignId) {
      campaignString = `?campaignId=${campaignId}`;
    }

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/Data/GetAllSourcesOfBusiness${campaignString}`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  // Get an individual Source of Business given it's name
  // Param is the Source of Business name
  fetchSourceOfBusinessByName: (name) => {
    const opts = {
      method: "GET",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
      },
    };

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/Data/GetSourceOfBusinessByName?name=${name}`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  // Given an ID, check that the Source of Business exists
  // Param sobId is the Source of Business ID
  checkSourceOfBusinessIdExists: (sobId) => {
    const opts = {
      method: "GET",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
      },
    };

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/Data/SourceOfBusinessIdExists?sourceOfBusinessId=${sobId}`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  // Given an offer / discount code, get the source of business discount banner HTML
  // param code is the offer / discount code to check
  fetchSourceOfBusinessDiscountBannerHtml: (code) => {
    const opts = {
      method: "GET",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
      },
    };

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/PedalCycle/GetSourceOfBusinessDiscountBannerHtml?code=${code}`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  // Given an offer / discount code, get the discount details
  // param code is the offer / discount code to check
  fetchOfferDetails: (code) => {
    const opts = {
      method: "GET",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
      },
    };

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/PedalCycle/GetOfferDetails?code=${code}`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  // Given a name, check that the Source of Business exists
  // param name is the Source of Business name
  checkSourceOfBusinessNameExists: (name) => {
    const opts = {
      method: "GET",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
      },
    };

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/Data/SourceOfBusinessNameExists?name=${name}`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  // method to fetch quote from Transactor API given risk data
  // param riskData - is the PedalCycle risk data needed to get quote
  fetchQuote: (riskData) => {
    const opts = {
      method: "POST",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
        "content-type": "application/json",
      },
      body: JSON.stringify(riskData),
    };

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/PedalCycle/GetQuote`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  fetchQuoteForScheme: (riskData) => {
    const opts = {
      method: "POST",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
        "content-type": "application/json",
      },
      body: JSON.stringify(riskData),
    };

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/PedalCycle/GetQuoteForScheme`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  sendQuoteEmails: (quoteEmailSendRequest) => {
    const opts = {
      method: "POST",
      headers: {
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
        "content-type": "application/json",
      },
      body: JSON.stringify(quoteEmailSendRequest),
    };

    return fetch(
      `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/PedalCycle/SendQuoteEmails`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  // method to incept policy from Transactor API given risk data
  // param transactorRequest - the populated request inclusive of config and risk data
  inceptPolicy: (riskData, fromExternalLink) => {
    const opts = {
      method: "POST",
      headers: {
        ApiKey: process.env.REACT_APP_AGGREGATOR_AUTH_KEY,
        authKey: process.env.REACT_APP_TRANSACTOR_AUTH_KEY,
        "content-type": "application/json",
      },
      body: riskData,
    };

    if(fromExternalLink === true){
      return fetch(
        `${process.env.REACT_APP_AGGREGATOR_API_ENDPOINT}/AggregatorQuote/InceptPolicy`,
        opts
      )
        .then(RestApiCommBaseService.handleResponse)
        .then((json) => {
          return json;
        });
    } else{
      
      return fetch(
        `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/PedalCycle/InceptPolicy`,
        opts
      )
        .then(RestApiCommBaseService.handleResponse)
        .then((json) => {
          return json;
        });
    }

  },
  // Generates risk data to incept a policy
  getPolicyRiskData: (payload) => {
    const coverStartDate = new Date(payload.coverStartDate);
    return null; // /////////////////////////////////////////////////////!!!!  this method is not used- there is a hook for this !!!
    // // // return {
    // // //     "proposer": {
    // // //         "title": payload.title,
    // // //         "titleId": payload.titleId,
    // // //         "forename": payload.forename,
    // // //         "initials": "",
    // // //         "surname": payload.surname,
    // // //         "emailAddress": payload.email,
    // // //         "dateOfBirth": payload.dob,
    // // //         "gender": "",
    // // //         "address": {
    // // //             "postcode": payload.postcode,
    // // //             "house": payload.houseNo,
    // // //             "street": payload.addressLine1,
    // // //             "locality": payload.addressLine2, // ??
    // // //             "city": payload.addressLine3, // ??
    // // //             "county": ""
    // // //         },
    // // //         "telephone": {
    // // //             "number": payload.telephoneNo
    // // //         }
    // // //     },
    // // //     "policy": {
    // // //         "coverStartDate": coverStartDate,
    // // //         "coverEndDate": coverStartDate.setFullYear(coverStartDate.getFullYear() + 1)
    // // //     },
    // // //     "marketing": { // TODO
    // // //         "sourceBusinessId": "410"
    // // //     },
    // // //     "paymentDetails": { // TODO
    // // //         "paymentTypeId": "9668C724CC764009BA7A899F5B632E10", // This comes from .env
    // // //         "bankDetails": {
    // // //             "accountNumber": "",
    // // //             "sortCode": "",
    // // //             "accountHolderName": ""
    // // //         }
    // // //     },
    // // //     "homeValue": 500, // TODO
    // // //     "awayValue": 500, // TODO
    // // //     "bicycles": payload.bikes,
    // // //     "hasSecondaryAddress": false, // TODO
    // // //     "isElectric": payload.isElectric,
    // // //     "includeWorldwideCover": payload.worldwideCover,
    // // //     "includeSportCover": payload.sportsCover,
    // // //     "includePersonalAccidentCover": payload.personalAccident,
    // // //     "includeRoadRageCover": true, // TODO
    // // //     "includeFamilyCover": true, // TODO
    // // //     "includeAccessoriesCover": payload.accessoryCover,
    // // //     "hasPreviousClaims": false, // TODO
    // // //     "secondaryAddress": { // TODO
    // // //         "house": "",
    // // //         "postcode": "",
    // // //         "locality": "",
    // // //         "city": "",
    // // //         "county": "",
    // // //         "country": ""
    // // //     },
    // // //     "globalPayTransactionId": "1657536483017955" // TODO
    // // // }
  },
};

export default TransactorService;
