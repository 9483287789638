import TopNavBar from "../../Shared/TopNavBar";
import RegularBanner from "../SHARED/RegularBanner";
import WhatOurCustomersSay from "../SHARED/WhatOurCustomersSay";
import ProtectYourBike from "../SHARED/ProtectYourBike";
import IntroBlock from "./IntroBlock";
import NeedToPurchase from "./NeedToPurchase";
import StorageRequirements from "./StorageRequirements";
import Stolen from "./Stolen";
import { useEffect } from "react";
import { seoTags } from "../../Shared/SeoEdit";
import { Helmet } from "react-helmet";

const SecurityRequirements = () => {
  useEffect(() => {
    seoTags(
      "Security Requirements",
      "We recommend using a secure and approved cycle lock. There are three ratings of lock, bronze, silver, and gold, depending on your cycle's value.",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);
  return (
    <div className="container-fluid whiteBG oh">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.velosure.co.uk/security-requirements"
        />
      </Helmet>
      <TopNavBar theme={"white"} />
      <RegularBanner
        headlineLine1={"Security"}
        headlineLine2={"requirements"}
        subheadlineLine1={
          "All the information you need to decide if our cover meets your needs"
        }
        subheadlineLine2={""}
        hasCTA={"true"}
        CTAText={"Get a quote"}
      />

      <IntroBlock />

      <NeedToPurchase />
      <StorageRequirements />
      <Stolen />
      <WhatOurCustomersSay />
      <div className="bg-lightblue">
        <ProtectYourBike variant={1} />
      </div>
    </div>
  );
};

export default SecurityRequirements;
