import { useContext, useState } from "react";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import TopNavBlank from "../../Shared/TopNavBlank";
import Breadcrumbs from "../../Shared/Breadcrumbs";
import RegularBanner from "../../MainSite/SHARED/RegularBanner";
import Payments from "../StepFour/Payments";
import Assumptions from "./Assumptions";
import Declarations from "./Declarations";
import DocumentPreferences from "../StepThree/DocumentPreferences";
import PaymentMethodSelector from "./PaymentMethodSelector";

const StepFour = () => {
  const [gState, setGState] = useContext(JourneyContext);
  const [rotate, setRotate] = useState(false);
  const [showPaymentWindow, setShowPaymentWindow] = useState(false);
  const [pending, setPending] = useState(false);
  
  return (
    <div className="container-fluid mb-5 blueBorderBott oh">
      <TopNavBlank theme={"white"} />
      <RegularBanner
        headlineLine1={"Your bike "}
        headlineLine2={"insurance quote"}
        subheadlineLine1={"Tell us about you, your bike and cover you need."}
        subheadlineLine2={""}
        hasCTA={"false"}
        rotate={rotate}
        CTAText={"Get a quote"}
      />
      <Breadcrumbs />

      <div hidden={!pending}>
        <div
          id="incepting-overlay"
          className={pending ? "overlay" : "overlay_hidden"}
        >
          <h1>Incepting your policy...</h1>{" "}
        </div>
      </div>

      <div hidden={!pending}>
        <div
          id="incepting-overlay"
          className={pending ? "overlay" : "overlay_hidden"}
        >
          <h1>Incepting your policy...</h1>{" "}
        </div>
      </div>

      <PaymentMethodSelector setShowPaymentWindow={setShowPaymentWindow}/>

      {gState.paymentSuccessful === false && (
        <>
         <Assumptions setShowPaymentWindow={setShowPaymentWindow} />
         <Declarations setShowPaymentWindow={setShowPaymentWindow} />
          <DocumentPreferences />
        </>
      )}

      {gState.paymentSuccessful === false && (
        <Payments setRotate={setRotate} showPaymentWindow={showPaymentWindow} setShowPaymentWindow={setShowPaymentWindow} setPending={setPending} />
      )}
    </div>
  );
};

export default StepFour;
