import TopNavBar from "../../Shared/TopNavBar";
import BenefitsOfEBike from "./BenefitsOfEBike";
import WhyChooseVelosure from "./WhyChooseVelosure";
import HeroBanner from "../SHARED/HeroBanner";
import WhatOurCustomersSay from "../SHARED/WhatOurCustomersSay";
import ProtectYourBike from "../SHARED/ProtectYourBike";
import { useEffect } from "react";
import { seoTags } from "../../Shared/SeoEdit";
import { Helmet } from "react-helmet";

const EBikeCover = () => {
  useEffect(() => {
    seoTags(
      "Electric bike cover",
      "Electric bike insurance covering against theft, accidental damage,and battery protection.",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);
  return (
    <div className="container-fluid">
      <Helmet>
        <link rel="canonical" href="https://www.velosure.co.uk/electric-bicycle-insurance" />
      </Helmet>
      <TopNavBar theme={"transparent"} />
      <HeroBanner
        heading1={"E-bike cover"}
        heading2={""}
        subHeading={
          "Accessible, versatile and fun for all ages, e-bikes are perfect for commuters, those wishing to build their fitness and thrill-seekers alike."
        }
        image={3}
      />
      <BenefitsOfEBike />
      <WhyChooseVelosure />
      <WhatOurCustomersSay />
      <ProtectYourBike variant={3} />
    </div>
  );
};

export default EBikeCover;
