import { Checkbox } from "@mantine/core";
import { useContext, useEffect, useRef } from "react";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import { editClassName, removeClassName } from "../../HELPERS/ClassNameEditor";

const IndividualBikeRow = ({
  bikeMake,
  bikeModel,
  lockName,
  bikeId,
  lockChecked,
  updateLockChecked,
}) => {
  const ref = useRef(null);
  const [gState, setGState] = useContext(JourneyContext);
  useEffect(() => {
    editClassName(
      ref,
      gState.clickedPayNow &&
        !gState.bikes.find((x) => x.id === bikeId).lockChecked,
      "mantine-Checkbox-error"
    );
  }, [gState]);
  const handleChange = (e) => {
    updateLockChecked(bikeId, e.target.checked);
    if (e.target.checked) {
      removeClassName(ref, "mantine-Checkbox-error");
    }
  };

  return (
    <div className="row mt-3">
      <div className="col-6 col-sm-6">
        <h6 className="lufga-regular wrap">
          {bikeMake} {bikeModel}
        </h6>
      </div>
      <div className="col-4 col-sm-4">
        <h6 className="lufga-regular">{lockName}</h6>
      </div>
      <div className="col-2 col-sm-2">
        <Checkbox
          radius="md"
          size="lg"
          ref={ref}
          color="velo-blue"
          className="float-end pr-3"
          value={lockChecked}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </div>
  );
};

export default IndividualBikeRow;
