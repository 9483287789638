import "./../CSS/Offers.css";
import OffersHelper from "./OffersHelper";

const OffersForJourneyNavBar = () => {
  return (
    <>
      {OffersHelper.isOfferActive() && (
        <div id="offerHeader">
          <h2>{sessionStorage.getItem('jouneryHeaderText')} </h2>
        </div>
      )}
    </>
  );
};

export default OffersForJourneyNavBar;
