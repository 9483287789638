import AboutYou from "./AboutYou";
import TopNavBar from "../../Shared/TopNavBar";
import Breadcrumbs from "../../Shared/Breadcrumbs";
import RegularBanner from "../../MainSite/SHARED/RegularBanner";
import CTAButton from "../../MainSite/SHARED/CTAButton";
import TopNavBlank from "../../Shared/TopNavBlank";

const StepTwo = () => {
  return (
    <div className="container-fluid mb-5 blueBorderBott oh">
      <TopNavBlank theme={"white"} />
      <RegularBanner
        headlineLine1={"Your bike "}
        headlineLine2={"insurance quote"}
        subheadlineLine1={"Tell us about you, your bike and cover you need."}
        subheadlineLine2={""}
        hasCTA={"false"}
        CTAText={"Get a quote"}
      />

      <Breadcrumbs currentPage="yourDetails" />

      <AboutYou />

    </div>
  );
};

export default StepTwo;
