const LoggingService = {
    logError: (message) => {
        return log('LogError', message);
    },
    logWarning: (message) => {
        return log('LogWarning', message);
    },
    logInfo: (message) => {
        return log('Log', message);
    },
}

const log = (url, message) => {
    const opts = {
        method: "POST",
        headers: {
            accept: "text/plain",
            "content-type": "application/json",
        },
        body: JSON.stringify({ "message": message, "applicationName": "Velosure" })
    };

    return fetch(
        `${process.env.REACT_APP_LOGGING_API_URL}/api/Logging/${url}`,
        opts
    );
}
export default LoggingService;