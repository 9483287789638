export const seoTags = (title, description, keywords) =>
{
    document.title = title;
    document.getElementsByTagName("meta")["description"].content = description;
    document.getElementsByTagName("meta")["keywords"].content = keywords;  
}

export const socialMediaTags = (title, description, image) =>
{
    document.getElementsByTagName("meta")["og-title"].content = title;
    document.getElementsByTagName("meta")["og-description"].content = description;
    document.getElementsByTagName("meta")["og-image"].content = image;
}