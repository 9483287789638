import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import "./CSS/site.css";
import { CookiesProvider } from "react-cookie";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

const container = document.getElementById("root");
const root = createRoot(container);
root.render(  <CookiesProvider><App tab="home" /></CookiesProvider>);
