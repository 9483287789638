import TopNavBar from "../../Shared/TopNavBar";
import RegularBanner from "../SHARED/RegularBanner";
import IntroBlock from "../COVER_COMPARISON/IntroBlock";
import CompareOurCover from "./CompareOurCover";
import WhatOurCustomersSay from "../SHARED/WhatOurCustomersSay";
import ProtectYourBike from "../SHARED/ProtectYourBike";
import { useEffect } from "react";
import { seoTags } from "../../Shared/SeoEdit";
import { Helmet } from "react-helmet";

const CoverComparison = () => {
  useEffect(() => {
    seoTags(
      "Cover Comparison",
      "Compare our coverage with leading insurance providers. Our helpful chart shows you the difference in price between market leaders and Velosure.",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);
  return (
    <div className="container-fluid">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.velosure.co.uk/bike-insurance-comparison-chart"
        />
      </Helmet>
      <TopNavBar theme={"white"} />
      <RegularBanner
        headlineLine1={"Compare our"}
        headlineLine2={"cover"}
        subheadlineLine1={"See how we compare to our competitors"}
        subheadlineLine2={""}
        hasCTA={"true"}
        CTAText={"Get a quote"}
      />
      <IntroBlock />
      <CompareOurCover />
      <WhatOurCustomersSay />
      <ProtectYourBike variant={1} />
    </div>
  );
};

export default CoverComparison;
