const RecaptchaHelper = {
    ProcessToken: (token) => {
        const opts = {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
            },
        };
        var url = `${process.env.REACT_APP_VELOSURE_API_URL}/api/Recaptcha/ProcessToken?token=${token}`;


        return fetch(
            url,
            opts
        )  .then((response) => response.json()).then(data => data?.score ?? 0);
    }
}
export default RecaptchaHelper;