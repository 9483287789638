import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import useFetch from "../../CUSTOMHOOKS/useFetch";
import useRiskModelAdaptor from "../../CUSTOMHOOKS/useRiskModelAdaptor";
import ModelAdaptorHelper from "../../HELPERS/ModelAdaptorHelper";
import RegularBanner from "../../MainSite/SHARED/RegularBanner";
import LoggingService from "../../Services/LoggingService";
import TransactorService from "../../Services/TransactorService";
import Breadcrumbs from "../../Shared/Breadcrumbs";
import TopNavBlank from "../../Shared/TopNavBlank";
import QuoteReferral from "./QuoteReferral";
import SummaryOfCover from "./SummaryOfCover";
import YourQuote from "./YourQuote";

const StepThree = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [gState, setGState] = useContext(JourneyContext);
  const [error, setError] = useState(null);
  const [coreQuote, setCoreQuote] = useState(null);
  const [performanceQuote, setPerformanceQuote] = useState(null);
  const [showQuoteDetails, setShowQuoteDetails] = useState(
    !gState.generateQuote
  );
  const [loading, setIsLoading] = useState(false);
  const [clickedButton, setClickedButton] = useState(false);
  const [showReQuoteMessage, setShowReQuoteMessage] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [sent, setSent] = useState(1);
  const [canProceedToPayment, setCanProceedToPayment] = useState(
    gState.selectedCoreScheme != null
  );
  const [showReferralPage, setShowReferralPage] = useState(false);
  const riskModel = JSON.stringify(useRiskModelAdaptor(gState));
  const [loadingQuote, setIsLoadingQuote] = useState(gState.generateQuote);
  const [logged, setLogged] = useState(gState.generateQuote);
  const [showReQuote, setShowReQuote] = useState(false);
  const TRANSACTOR_AUTH_KEY =
    "Velosure|0o4ymBa41mIsQCXDFzQx+c6ttkMrfmWrLdeGANwffgs=";

  const url = `${process.env.REACT_APP_TRANSACTOR_API_ENDPOINT}/PedalCycle/GetQuote`; // this method Generates the quote- poorly named!
  const options = {
    method: "POST",
    headers: {
      authKey: TRANSACTOR_AUTH_KEY,
      "Content-Type": "application/json",
    },
    body: riskModel,
    mode: "cors",
  };

  const response = useFetch(url, options, !gState.generateQuote);
  useEffect(() => {
    const updatedState =
      ModelAdaptorHelper.resetAssumptionsAndDeclarations(gState);
    setGState({
      ...updatedState,
      paymentCrumb: 0,
      yourDetailsCrumb:
        gState.yourDetailsCrumb === 2 ? 1 : gState.yourDetailsCrumb,
      yourCoverCrumb: gState.yourCoverCrumb === 2 ? 1 : gState.yourCoverCrumb,
      yourQuoteCrumb: 2,
      currentlyEditingABike: false,
      currentlyAddingABike: false,
    });
    if (gState.generateQuote) {
      if (response === null || response.data === null) {
        setError(
          <p>
            Something went wrong generating your quote, please try again later
            or contact our customer service team on{" "}
            <a href="tel:08000833035" target="_blank" rel="noreferrer">
              <strong>0800 083 3035 </strong>
            </a>
          </p>
        );
        setShowQuoteDetails(false);
        setIsLoadingQuote(false);
        return;
      }

      //store response in state
      if (response.data.value) {
        const performanceQuote = response.data.value.find(
          (f) => !f.schemeName.toLowerCase().includes("core")
        );
        setPerformanceQuote(performanceQuote);
        const coreValues = response.data.value.find((f) =>
          f.schemeName.toLowerCase().includes("core")
        );
        setCoreQuote(coreValues);
        if (performanceQuote.referralReason || performanceQuote.declineReason) {
          if (showReferralPage) {
            return;
          }
          LoggingService.logWarning(
            `Quote ${performanceQuote?.quoteReference} referred or declined with reason: ${performanceQuote.referralReason} ${performanceQuote.declineReason}`
          );
          setError(null);
          setGState({
            ...gState,
            annualGrossPremium: performanceQuote.annualGrossPremium,
            basePremium: performanceQuote.basePremium,
            commission: performanceQuote.commission,
            declineReason: performanceQuote.declineReason,
            instalmentsApr: performanceQuote.instalmentsApr,
            instalmentsFirstPayment: performanceQuote.instalmentsFirstPayment,
            instalmentsGrossPremium: performanceQuote.instalmentsGrossPremium,
            instalmentsInterestPc: performanceQuote.instalmentsInterestPc,
            instalmentsServiceCharge: performanceQuote.instalmentsServiceCharge,
            instalmentsSubsequentPayments:
              performanceQuote.instalmentsSubsequentPayments,
            ipt: performanceQuote.ipt,
            netPremium: performanceQuote.netPremium,
            quoteReference: performanceQuote.quoteReference,
            referralReason: performanceQuote.referralReason,
            schemeId: performanceQuote.schemeId,
            schemeTable: performanceQuote.schemeTable,
          });
          setShowReferralPage(true);
          setIsLoadingQuote(false);
          return;
        }

        if (performanceQuote.annualGrossPremium > 0) {
          setGState({
            ...gState,
            coreQuote: coreValues,
            performanceQuote: performanceQuote,
            initQuote: performanceQuote,
            annualGrossPremium: performanceQuote.annualGrossPremium,
            instalmentsSubsequentPayments:
              performanceQuote.instalmentsSubsequentPayments,
            generateQuote: false,
          });
          setError(null);
          setShowQuoteDetails(true);
          setIsLoadingQuote(false);
          //send a quote email - only ONCE!!
          if (!emailSent) {
            LoggingService.logInfo(
              `Email sent ${sent} time(s) for Quotes: ${coreValues.quoteReference} & ${performanceQuote.quoteReference}`
            );
            setSent(sent + 1);
            setEmailSent(true);
            TransactorService.sendQuoteEmails({
              quoteReferences: [
                performanceQuote.quoteReference,
                coreValues.quoteReference,
              ],
            });
          }
          return;
        }
      }
    }
  }, [response]);

  const onClick = (e) => {
    e.preventDefault();
    if (!canProceedToPayment) {
      setClickedButton(true);
      const ele = document.getElementById("yourQuote");
      ele.scrollIntoView({ behavior: "smooth", block: "center" });
    } else if (showReQuote &&   gState.selectedCoreScheme  != true) {
      const button = document.getElementById("yourQuote");
      setShowReQuoteMessage(true);
      button.scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      window.scrollTo(0, 0);
      history.push(`/stepFour${search}`);
    }
  };
  return (
    <>
      <div className="container-fluid mb-5 blueBorderBott oh">
        <TopNavBlank theme={"white"} />
        <RegularBanner
          headlineLine1={"Your bike "}
          headlineLine2={"insurance quote"}
          subheadlineLine1={"Tell us about you, your bike and cover you need."}
          subheadlineLine2={""}
          hasCTA={"false"}
          CTAText={"Get a quote"}
          rotate={response.isPending || loading || loadingQuote}
        />
        <div
          className={
            response.isPending || loading || loadingQuote
              ? "overlay"
              : "overlay_hidden"
          }
        >
          <h1 className="GettingQuoteOverlayH1">
            {loading
              ? "Getting your updated quote..."
              : "Getting your quote..."}
          </h1>
        </div>

        <Breadcrumbs />

        {!response.isPending && !showReferralPage && (
          <YourQuote
            error={error}
            coreQuote={coreQuote ?? gState.coreQuote}
            setIsLoading={setIsLoading}
            showError={clickedButton}
            showReQuote={showReQuote}
            setShowReQuote={setShowReQuote}
            canProceedToPayment={setCanProceedToPayment}
            showReQuoteMessage={showReQuoteMessage}
            setShowReQuoteMessage={setShowReQuoteMessage}
            performanceQuote={performanceQuote ?? gState.performanceQuote}
          />
        )}
        {showQuoteDetails && (
          <>
            <SummaryOfCover />

            <div className="container container_narrow">
              <div className="row">
                <div className="col-6 mb-4 mt-5">
                  <Link
                    className="btn btn-wider btn-secondary float-start"
                    to={`/stepTwo${search}`}
                  >
                    Back
                  </Link>
                </div>
                <div className="col-6 mb-4 mt-5 ">
                  <button
                    id={`move-to-step-four#${
                      gState.selectedCoreScheme ? "core" : "performance"
                    }`}
                    className="btn btn-wider btn-green float-end"
                    onClick={onClick}
                  >
                    Next step
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {showReferralPage && (
          <QuoteReferral quoteReference={gState.quoteReference} />
        )}
      </div>
    </>
  );
};

export default StepThree;
