import { useLocation } from "react-router-dom";
import CTAButton from "../SHARED/CTAButton";

const CorePerformanceIntro = () => {
  const { search } = useLocation();
  return (
    <div className="container-fluid lightBlueBG pr oh">
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 col-md-6">
            <p className="lufga-medium CorePerformanceIntroFontSize pr-5">
              Whether you cycle for transport, fun, fitness or sport, having the
              right level of{" "}
              <span className="blueFont">
                cover to meet your needs is essential
              </span>
              .
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="font-27 lufga-medium mb-3">
              With Velosure you will benefit from:
            </p>

            <div className="row">
              <div className="col-12 col-md-6">
                <article className="bikeBucket text-left mb-3">
                  <svg
                    width="80.729"
                    height="78.881"
                    viewBox="0 0 80.729 78.881"
                  >
                    <defs>
                      <filter
                        id="Rectangle_1517"
                        x="4.243"
                        y="2.394"
                        width="76.487"
                        height="76.487"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <filter
                        id="Rectangle_1518"
                        x="0"
                        y="0"
                        width="76.487"
                        height="76.487"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur-2" />
                        <feFlood floodOpacity="0.031" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <clipPath id="clipPath">
                        <rect
                          id="Rectangle_1637"
                          data-name="Rectangle 1637"
                          width="26.656"
                          height="45.164"
                          transform="translate(0 0)"
                          fill="#fff"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="0.5"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Group_2946"
                      data-name="Group 2946"
                      transform="translate(6 2)"
                    >
                      <g id="Group_2943" data-name="Group 2943">
                        <g
                          transform="matrix(1, 0, 0, 1, -6, -2)"
                          filter="url(#Rectangle_1517)"
                        >
                          <rect
                            id="Rectangle_1517-2"
                            data-name="Rectangle 1517"
                            width="64.487"
                            height="64.487"
                            rx="17"
                            transform="translate(10.24 5.39)"
                            fill="rgba(0,168,255,0.1)"
                          />
                        </g>
                        <g id="Group_2944" data-name="Group 2944">
                          <g
                            id="Group_2945"
                            data-name="Group 2945"
                            transform="translate(0 1)"
                          >
                            <g
                              transform="matrix(1, 0, 0, 1, -6, -3)"
                              filter="url(#Rectangle_1518)"
                            >
                              <rect
                                id="Rectangle_1518-2"
                                data-name="Rectangle 1518"
                                width="64.487"
                                height="64.487"
                                rx="17"
                                transform="translate(6 3)"
                                fill="#00a8ff"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_2942"
                        data-name="Group 2942"
                        transform="translate(21.037 9.252)"
                      >
                        <g
                          id="Group_2934"
                          data-name="Group 2934"
                          transform="translate(0 0)"
                          clipPath="url(#clipPath)"
                        >
                          <path
                            id="Path_2275"
                            data-name="Path 2275"
                            d="M25.955,84.078a.7.7,0,0,1-.258-.049l-12.37-4.9-12.37,4.9A.7.7,0,0,1,0,83.374v-7.5a.7.7,0,0,1,.442-.651l12.628-5a.7.7,0,0,1,.515,0l12.627,5a.7.7,0,0,1,.442.651v7.5a.7.7,0,0,1-.7.7m-12.628-6.4a.707.707,0,0,1,.258.049l11.669,4.62v-5.99L13.328,71.634,1.4,76.354v5.991l11.67-4.618a.7.7,0,0,1,.258-.049"
                            transform="translate(0 -38.913)"
                            fill="#fff"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.5"
                          />
                          <path
                            id="Path_2276"
                            data-name="Path 2276"
                            d="M25.955,48.987a.7.7,0,0,1-.258-.049l-12.37-4.9-12.37,4.9A.7.7,0,0,1,0,48.284v-7.5a.7.7,0,0,1,.442-.651l12.628-5a.7.7,0,0,1,.515,0l12.627,5a.7.7,0,0,1,.442.651v7.5a.7.7,0,0,1-.7.7m-12.628-6.4a.7.7,0,0,1,.258.049l11.669,4.62v-5.99L13.328,36.543,1.4,41.263v5.991l11.67-4.618a.694.694,0,0,1,.258-.049"
                            transform="translate(0 -19.456)"
                            fill="#fff"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.5"
                          />
                          <path
                            id="Path_2277"
                            data-name="Path 2277"
                            d="M25.955,13.9a.7.7,0,0,1-.258-.049l-12.37-4.9-12.37,4.9A.7.7,0,0,1,0,13.194V5.7a.7.7,0,0,1,.442-.651l12.628-5a.7.7,0,0,1,.515,0l12.627,5a.7.7,0,0,1,.442.651v7.5a.7.7,0,0,1-.7.7M13.328,7.5a.707.707,0,0,1,.258.049l11.669,4.62V6.175L13.328,1.453,1.4,6.173v5.991L13.07,7.545a.7.7,0,0,1,.258-.049"
                            transform="translate(0 0)"
                            fill="#fff"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.5"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>

                  <p className="font-16">
                    Market leading cover at a marketing beating price
                  </p>
                </article>
              </div>
              <div className="col-12 col-md-6">
                <article className="bikeBucket text-left mb-3">
                  <svg
                    width="80.729"
                    height="79.881"
                    viewBox="0 0 80.729 79.881"
                  >
                    <defs>
                      <filter
                        id="Rectangle_1517"
                        x="4.243"
                        y="3.394"
                        width="76.487"
                        height="76.487"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <filter
                        id="Rectangle_1518"
                        x="0"
                        y="0"
                        width="76.487"
                        height="76.487"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur-2" />
                        <feFlood floodOpacity="0.031" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      {/* <clipPath id="clip-path999">
                        <rect
                          id="Rectangle_1634"
                          data-name="Rectangle 1634"
                          width="44.486"
                          height="41.943"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                      </clipPath> */}
                    </defs>
                    <g
                      id="Group_2946"
                      data-name="Group 2946"
                      transform="translate(6 3)"
                    >
                      <g
                        transform="matrix(1, 0, 0, 1, -6, -3)"
                        filter="url(#Rectangle_1517)"
                      >
                        <rect
                          id="Rectangle_1517-2"
                          data-name="Rectangle 1517"
                          width="64.487"
                          height="64.487"
                          rx="17"
                          transform="translate(10.24 6.39)"
                          fill="rgba(0,168,255,0.1)"
                        />
                      </g>
                      <g
                        transform="matrix(1, 0, 0, 1, -6, -3)"
                        filter="url(#Rectangle_1518)"
                      >
                        <rect
                          id="Rectangle_1518-2"
                          data-name="Rectangle 1518"
                          width="64.487"
                          height="64.487"
                          rx="17"
                          transform="translate(6 3)"
                          fill="#00a8ff"
                        />
                      </g>
                      <g
                        id="Group_2931"
                        data-name="Group 2931"
                        transform="translate(10.34 11.557)"
                        clipPath="url(#clip-path999)"
                      >
                        <path
                          id="Path_2258"
                          data-name="Path 2258"
                          d="M37.313,2.1a12.6,12.6,0,1,0,3.531,17.468A12.6,12.6,0,0,0,37.313,2.1m1.371,16.036a10.011,10.011,0,1,1-2.8-13.877,10.009,10.009,0,0,1,2.8,13.877"
                          transform="translate(-9.497 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2259"
                          data-name="Path 2259"
                          d="M26.373,51.055l-.814-.54a1.135,1.135,0,0,1-.318-1.573l.289-.435a1.135,1.135,0,0,1,1.573-.318l.814.54a1.134,1.134,0,0,1,.317,1.573l-.289.435a1.135,1.135,0,0,1-1.573.318"
                          transform="translate(-13.41 -25.693)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2260"
                          data-name="Path 2260"
                          d="M4.049,69.269.507,66.918A1.135,1.135,0,0,1,.222,65.3l9.948-13.431a1.134,1.134,0,0,1,1.539-.27L13.82,53a1.135,1.135,0,0,1,.349,1.523L5.653,68.9a1.135,1.135,0,0,1-1.6.367"
                          transform="translate(0 -27.516)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2261"
                          data-name="Path 2261"
                          d="M29.275,23.986V4.3a9.828,9.828,0,0,0-2.323,1.258,1.614,1.614,0,0,1-.425.252V22.239c.922.578,1.846,1.146,2.748,1.747"
                          transform="translate(-14.199 -2.3)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2262"
                          data-name="Path 2262"
                          d="M54.174,17.984H43.4V20.37H55.055a12.014,12.014,0,0,0-.881-2.386"
                          transform="translate(-23.229 -9.626)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2263"
                          data-name="Path 2263"
                          d="M55.49,28.7H43.4v2.386H55.457A13.922,13.922,0,0,0,55.49,28.7"
                          transform="translate(-23.229 -15.361)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2264"
                          data-name="Path 2264"
                          d="M54.616,39.411H43.4V41.8H52a9.871,9.871,0,0,0,2.621-2.386"
                          transform="translate(-23.229 -21.095)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2265"
                          data-name="Path 2265"
                          d="M68.111,52.871H52.657q-.3.1-.6.183v1.165H68.111Z"
                          transform="translate(-27.862 -28.3)"
                          fill="#fff"
                        />
                        <rect
                          id="Rectangle_1632"
                          data-name="Rectangle 1632"
                          width="16.058"
                          height="1.348"
                          transform="translate(24.191 30.194)"
                          fill="#fff"
                        />
                        <rect
                          id="Rectangle_1633"
                          data-name="Rectangle 1633"
                          width="16.058"
                          height="1.348"
                          transform="translate(24.191 27.383)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2266"
                          data-name="Path 2266"
                          d="M74.234,30.019V28.671H71.757a12.594,12.594,0,0,1-.15,1.348Z"
                          transform="translate(-38.329 -15.347)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2267"
                          data-name="Path 2267"
                          d="M77.646,34.72h-7.3a12.443,12.443,0,0,1-.475,1.348h7.78Z"
                          transform="translate(-37.397 -18.584)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2268"
                          data-name="Path 2268"
                          d="M72.279,46.821H61.532a12.666,12.666,0,0,1-1.692,1.348H72.279Z"
                          transform="translate(-32.031 -25.062)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2269"
                          data-name="Path 2269"
                          d="M60.623,13.871H55.847a.765.765,0,0,0-.707,1.065l.007.015a.775.775,0,0,0,.707.472h3.44a.768.768,0,0,1,.768.768v4.1a.768.768,0,0,0,.768.768h4.1a.768.768,0,0,1,.767.768V42.894a.767.767,0,0,1-.767.768H44.847a.767.767,0,0,1-.768-.768V32.618c-.156.006-.311.012-.468.012-.1,0-.2,0-.3,0a.764.764,0,0,0-.786.764V44.446a.767.767,0,0,0,.768.768H66.483a.768.768,0,0,0,.768-.768V20.5a.772.772,0,0,0-.225-.543l-5.86-5.86a.767.767,0,0,0-.543-.225"
                          transform="translate(-22.764 -7.425)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2270"
                          data-name="Path 2270"
                          d="M75.754,40.77H67.241a12.641,12.641,0,0,1-.909,1.348h9.422Z"
                          transform="translate(-35.505 -21.823)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>

                  <p className="font-16 pr-5">
                    Clear, transparent policy with no ambiguity
                  </p>
                </article>
              </div>
              <div className="col-12 col-md-6">
                <article className="bikeBucket text-left mb-3">
                  <svg
                    width="80.729"
                    height="79.881"
                    viewBox="0 0 80.729 79.881"
                  >
                    <defs>
                      <filter
                        id="Rectangle_1517"
                        x="4.243"
                        y="3.394"
                        width="76.487"
                        height="76.487"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <filter
                        id="Rectangle_1518"
                        x="0"
                        y="0"
                        width="76.487"
                        height="76.487"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur-2" />
                        <feFlood floodOpacity="0.031" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <clipPath id="clip-path111">
                        <rect
                          id="Rectangle_1635"
                          data-name="Rectangle 1635"
                          width="48.784"
                          height="39.479"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Group_2948"
                      data-name="Group 2948"
                      transform="translate(6 3)"
                    >
                      <g
                        transform="matrix(1, 0, 0, 1, -6, -3)"
                        filter="url(#Rectangle_1517)"
                      >
                        <rect
                          id="Rectangle_1517-2"
                          data-name="Rectangle 1517"
                          width="64.487"
                          height="64.487"
                          rx="17"
                          transform="translate(10.24 6.39)"
                          fill="rgba(0,168,255,0.1)"
                        />
                      </g>
                      <g
                        transform="matrix(1, 0, 0, 1, -6, -3)"
                        filter="url(#Rectangle_1518)"
                      >
                        <rect
                          id="Rectangle_1518-2"
                          data-name="Rectangle 1518"
                          width="64.487"
                          height="64.487"
                          rx="17"
                          transform="translate(6 3)"
                          fill="#00a8ff"
                        />
                      </g>
                      <g
                        id="Group_2932"
                        data-name="Group 2932"
                        transform="translate(7.813 12.768)"
                        clipPath="url(#clip-path111)"
                      >
                        <path
                          id="Path_2271"
                          data-name="Path 2271"
                          d="M60.02,33.957,42.207,17.607a1.508,1.508,0,0,0-.26-1.789l-.446-.446,2.284-2.284a2.444,2.444,0,0,0,.512-.752l.019.019a1.91,1.91,0,0,0,2.7,0L48.367,11a1.909,1.909,0,0,0,0-2.7L40.624.559a1.908,1.908,0,0,0-2.7,0L36.572,1.912a1.91,1.91,0,0,0,0,2.7l.019.019a2.439,2.439,0,0,0-.752.512l-9.127,9.127a2.45,2.45,0,0,0-.513.752L26.181,15a1.909,1.909,0,0,0-2.7,0l-1.353,1.353a1.91,1.91,0,0,0,0,2.7L29.872,26.8a1.908,1.908,0,0,0,2.7,0l1.353-1.353a1.908,1.908,0,0,0,0-2.7l-.019-.019a2.441,2.441,0,0,0,.753-.512l2.284-2.284.446.446a1.532,1.532,0,0,0,1.789.26L55.527,38.45a3.18,3.18,0,1,0,4.493-4.493M38.461,19.237l-.413-.413,2.347-2.347.413.413ZM57.883,38.131l-.013-.216a1.62,1.62,0,0,1-1.191-.523L40.33,19.58l.82-.82,17.813,16.35a1.616,1.616,0,0,1-1.081,2.806Zm-25.6-17.022-4.47-4.47a.895.895,0,0,1,0-1.265l9.127-9.127a.9.9,0,0,1,1.264,0l4.472,4.472a.9.9,0,0,1,0,1.264l-9.127,9.127a.9.9,0,0,1-1.265,0m5.39-18.091,1.353-1.353a.37.37,0,0,1,.487,0l7.743,7.743a.346.346,0,0,1,0,.487l-1.353,1.353a.346.346,0,0,1-.488,0L43.81,9.637,37.678,3.5a.345.345,0,0,1,0-.487M32.919,24.094a.338.338,0,0,1-.1.244l-1.353,1.353a.358.358,0,0,1-.487,0l-7.743-7.743a.345.345,0,0,1,0-.487l1.352-1.353a.345.345,0,0,1,.487,0l7.743,7.743a.34.34,0,0,1,.1.243"
                          transform="translate(-12.265 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_2272"
                          data-name="Path 2272"
                          d="M24.015,71.913h-.757V70.539a2.955,2.955,0,0,0-2.952-2.952H6.661A2.955,2.955,0,0,0,3.71,70.539v1.374H2.952A2.955,2.955,0,0,0,0,74.864V76.8a.783.783,0,0,0,.782.782h25.4a.783.783,0,0,0,.782-.782V74.864a2.955,2.955,0,0,0-2.952-2.951m-13.867.782a.783.783,0,0,0-.782-.782H5.273V70.539a1.39,1.39,0,0,1,1.388-1.388H20.306a1.39,1.39,0,0,1,1.388,1.388v1.374H20.461a.782.782,0,0,0,0,1.563h3.553A1.39,1.39,0,0,1,25.4,74.864v1.153H1.564V74.864a1.39,1.39,0,0,1,1.388-1.388H9.365a.783.783,0,0,0,.782-.782"
                          transform="translate(0 -38.429)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>

                  <p className="font-16 pr-5">
                    Internal Claims Team – allowing you to choose your own
                    repairer or replacement retailer
                  </p>
                </article>
              </div>
              <div className="col-12 col-md-6">
                <article className="bikeBucket text-left mb-5">
                  <svg
                    width="80.729"
                    height="79.881"
                    viewBox="0 0 80.729 79.881"
                  >
                    <defs>
                      <filter
                        id="Rectangle_1517"
                        x="4.243"
                        y="3.394"
                        width="76.487"
                        height="76.487"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <filter
                        id="Rectangle_1518"
                        x="0"
                        y="0"
                        width="76.487"
                        height="76.487"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="2" result="blur-2" />
                        <feFlood floodOpacity="0.031" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <clipPath id="clip-path222">
                        <rect
                          id="Rectangle_1636"
                          data-name="Rectangle 1636"
                          width="48.139"
                          height="31.98"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Group_2950"
                      data-name="Group 2950"
                      transform="translate(6 3)"
                    >
                      <g id="Group_2949" data-name="Group 2949">
                        <g
                          transform="matrix(1, 0, 0, 1, -6, -3)"
                          filter="url(#Rectangle_1517)"
                        >
                          <rect
                            id="Rectangle_1517-2"
                            data-name="Rectangle 1517"
                            width="64.487"
                            height="64.487"
                            rx="17"
                            transform="translate(10.24 6.39)"
                            fill="rgba(0,168,255,0.1)"
                          />
                        </g>
                      </g>
                      <g
                        transform="matrix(1, 0, 0, 1, -6, -3)"
                        filter="url(#Rectangle_1518)"
                      >
                        <rect
                          id="Rectangle_1518-2"
                          data-name="Rectangle 1518"
                          width="64.487"
                          height="64.487"
                          rx="17"
                          transform="translate(6 3)"
                          fill="#00a8ff"
                        />
                      </g>
                      <g
                        id="Group_2939"
                        data-name="Group 2939"
                        transform="translate(8.205 18.422)"
                      >
                        <g
                          id="Group_2933"
                          data-name="Group 2933"
                          transform="translate(0 0.001)"
                          clipPath="url(#clip-path222)"
                        >
                          <path
                            id="Path_2273"
                            data-name="Path 2273"
                            d="M60.92,14.475,53.251.381a.73.73,0,0,0-.99-.292L47.136,2.878a.685.685,0,0,0-.12.082.714.714,0,0,0-.064.064c-.012.013-.025.024-.036.037a.684.684,0,0,0-.057.086c-.006.011-.015.021-.021.032a.73.73,0,0,0-.08.272c0,.013,0,.026,0,.04a.705.705,0,0,0,0,.1c0,.019.008.038.011.057a.764.764,0,0,0,.017.082.739.739,0,0,0,.056.134l7.455,13.7-2.292,3.287a8.058,8.058,0,0,1-2.747,2.459L36.34,30.343a1.459,1.459,0,0,1-1.4-2.56l4.489-2.443a.729.729,0,1,0-.7-1.281L34.248,26.5l-.006,0-2.557,1.392a1.459,1.459,0,0,1-1.4-2.559L37.339,21.5a.729.729,0,0,0-.7-1.281l-7.048,3.835-.01.006-2.552,1.389a1.459,1.459,0,1,1-1.394-2.562l9.61-5.23a.729.729,0,1,0-.7-1.281L27.5,20.205a1.459,1.459,0,1,1-1.394-2.563l2.886-1.57a.729.729,0,1,0-.7-1.281L25.41,16.362a2.917,2.917,0,0,0-.126,5.051l-.345.187a2.918,2.918,0,0,0,2.79,5.125l.342-.186a2.919,2.919,0,0,0,4.312,2.635l.344-.187a2.915,2.915,0,0,0,4.311,2.637l12.92-7.031A9.52,9.52,0,0,0,53.2,21.687l2.451-3.515,4.974-2.707a.729.729,0,0,0,.292-.989m-5.474,2.148L48.474,3.81l3.844-2.092L59.29,14.532Z"
                            transform="translate(-12.87 0)"
                            fill="#fff"
                          />
                          <path
                            id="Path_2274"
                            data-name="Path 2274"
                            d="M7.208,20.118l.171-.689L10.859,5.42l3.91-.875a8.058,8.058,0,0,1,3.686.043L29.9,7.43a1.459,1.459,0,0,1-.7,2.832L22.826,8.679a.73.73,0,0,0-.884.532,5.106,5.106,0,0,1-6.186,3.724l-1.416-.351A.729.729,0,0,0,13.989,14l1.415.351a6.567,6.567,0,0,0,7.739-4.09l5.7,1.416a2.918,2.918,0,0,0,1.407-5.663L18.807,3.172a9.535,9.535,0,0,0-4.357-.051l-4.182.936-5.5-1.365a.73.73,0,0,0-.884.532L.021,18.8a.729.729,0,0,0,.532.884l5.5,1.365L9.307,23.83a.729.729,0,1,0,.948-1.109ZM5.129,4.284,9.377,5.339,5.86,19.5,1.613,18.441Z"
                            transform="translate(0 -1.468)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>

                  <p className="font-16 pr-5">No quibble settlement</p>
                </article>
              </div>
            </div>

            <p className="font-17">
              Velosure policy holders can choose between our two market-leading
              solutions, so you know that you, and your bike, are protected, no
              matter the purpose behind your pedalling.
            </p>
            <div className="mt-5 mb-5">
              <CTAButton
                align="left"
                colour="green"
                CTAText="Get a quote"
                onClick={() => {
                  sessionStorage.removeItem("context");
                }}
                Url={`/get-a-quote${search}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorePerformanceIntro;
