import { useContext, useEffect, useState } from "react";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import YourQuote from "../StepThree/YourQuote";
import TopNavBlank from "../../Shared/TopNavBlank";
import RegularBanner from "../../MainSite/SHARED/RegularBanner";
import SummaryOfCover from "../StepThree/SummaryOfCover";
import { Link, useLocation, useHistory } from "react-router-dom";
const QRQuoteSummary = () => {
    const [gState, setGState] = useContext(JourneyContext);
    const history = useHistory();
    const [loading, setIsLoading] = useState(false);
    const [clickedButton, setClickedButton] = useState(false);
    const [canProceedToPayment, setCanProceedToPayment] = useState(true);
    const [validateNextButton, setValidateNextButton] = useState(false);


    const onProceedToPayment = (e) => {
        e.preventDefault();
        setValidateNextButton(true);
        if (gState.hasPreviousClaim == null) {
            return;
        }


        if (!canProceedToPayment) {
            setClickedButton(true);
            const ele = document.getElementById('yourQuote');
            ele.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
            window.scrollTo(0, 0);
            history.push(`/QuoteRetrievalPayment`)
        }
    }

    const amendDetails = (e) => {
        e.preventDefault();
        setGState({
            ...gState,
            yourCoverCrumb: 0,
            yourDetailsCrumb: 1,
            yourQuoteCrumb: 1,
            paymentCrumb: 0,
        });
        history.push(`/get-a-quote`)
    }

    return (
        <div className="container-fluid mb-5 oh">
            <TopNavBlank theme={"white"} />
            <RegularBanner
                headlineLine1={"Retrieve"}
                headlineLine2={"your quote"}
                subheadlineLine1={"Retrieve your quote and purchase your insurance"}
                subheadlineLine2={""}
                hasCTA={"false"}

                rotate={false}
            />
            <div className={(loading) ? "overlay" : "overlay_hidden"}>
                <h1 className="GettingQuoteOverlayH1">{loading ? 'Getting your updated quote...' : 'Getting your quote...'}</h1>
            </div>
            {!loading &&
                (<>
                    <YourQuote
                        error={""}
                        coreQuote={gState.coreQuote}
                        setIsLoading={setIsLoading}
                        showError={clickedButton}
                        fromExternalLink={true}
                        canProceedToPayment={setCanProceedToPayment}
                        performanceQuote={gState.performanceQuote}
                    />



                    <div id="aggregator-quote">
                        <SummaryOfCover detailsText="Please ensure all of the details are correct. To amend any information click the 'Amend details' button below." fromExternalLink={true} validateNextButton={validateNextButton} />
                        <div className="container container_narrow">
                            <div className="row my-5">
                                <div className="col-12 mb-4 mt-3">
                                    <div
                                        className="btn btn-wider btn-red float-start"
                                        id="amend-details"
                                        onClick={amendDetails}
                                    >
                                        Amend details
                                    </div>
                                    <div className="col-12">
                                        <div
                                            id={`aggregator-proceed-to-payment#${gState.selectedCoreScheme ? "core" : "performance"}`}
                                            className="btn btn-wider btn-green float-end"
                                            onClick={onProceedToPayment}
                                        >
                                            Confirm details
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> </>)
            }
        </div>
    );
};
export default QRQuoteSummary;
