const CoverData = () => {
  return {
    CoverLevels: [
      {
        id: 147,
        c1: "",
        c2: "Core",
        c3: "Performance",
      },

      {
        id: 150,
        c1: "Accidental damage",
        c2: 1,
        c3: 1,
      },
      {
        id: 151,
        c1: "Theft",
        c2: 1,
        c3: 1,
      },
      {
        id: 152,
        c1: "Public liability",
        c2: 1,
        c3: 1,
      },

      {
        id: 154,
        c1: "Accessory cover",
        c2: 1,
        c3: 1,
      },
      {
        id: 155,
        c1: "Replacement cycle hire",
        c2: 1,
        c3: 1,
      },
      {
        id: 156,
        c1: "Family cover",
        c2: 1,
        c3: 1,
      },
      {
        id: 157,
        c1: "New for old",
        c2: 1,
        c3: 1,
      },

      {
        id: 159,
        c1: "Triage",
        c2: 1,
        c3: 1,
      },
      {
        id: 160,
        c1: "Physio",
        c2: 1,
        c3: 1,
      },
      {
        id: 161,
        c1: "Sports cover",
        c2: <div className="greyFont">-</div>,
        c3: 3,
      },
      {
        id: 162,
        c1: "European cover",
        c2: <div className="greyFont">-</div>,
        c3: 1,
      },
      {
        id: 163,
        c1: "Worldwide cover",
        c2: <div className="greyFont">-</div>,
        c3: 3,
      },
      {
        id: 164,
        c1: "24/7 Cycle rescue",
        c2: <div className="greyFont">-</div>,
        c3: 1,
      },
      {
        id: 165,
        c1: "Road rage assault",
        c2: <div className="greyFont">-</div>,
        c3: 3,
      },
      {
        id: 166,
        c1: "Personal accident",
        c2: <div className="greyFont">-</div>,
        c3: 3,
      },
    ],
    Multibike: [
      {
        id: 47,
        c1: "",
        c2: "Velosure",
        c3: "Laka",
        c4: "Yellow Jersey",
        c5: "Bikmo",
        c6: "Direct Line",
        c7: "Wiggle",
      },
      {
        id: 48,
        c1: "£4000 cover",
        c2: "£229",
        c3: "£264",
        c4: "£209",
        c5: "£288",
        c6: "£281",
        c7: "£256",
      },
      {
        id: 49,
        c1: "£8000 cover",
        c2: "£436",
        c3: "£492",
        c4: "£341",
        c5: "£576",
        c6: "£526",
        c7: "£476",
      },
      {
        id: 50,
        c1: "Accidental damage",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 1,
      },
      {
        id: 51,
        c1: "Theft",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 1,
      },
      {
        id: 52,
        c1: "Public liability",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: "Optional",
        c6: "Optional",
        c7: 1,
      },
      {
        id: 53,
        c1: "Sports & events",
        c2: 1,
        c3: 1,
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
      {
        id: 54,
        c1: "Accessory cover",
        c2: 1000,
        c3: "Optional",
        c4: 250,
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
      {
        id: 55,
        c1: "Cycle rescue",
        c2: 1,
        c3: 0,
        c4: "Optional",
        c5: 0,
        c6: 1,
        c7: 0,
      },
      {
        id: 56,
        c1: "Road rage assault",
        c2: 1,
        c3: "Optional",
        c4: 1,
        c5: "Optional",
        c6: 0,
        c7: 1,
      },
      {
        id: 57,
        c1: "Personal accident",
        c2: 1,
        c3: 0,
        c4: 1,
        c5: "Optional",
        c6: "Optional",
        c7: 1,
      },
      {
        id: 58,
        c1: "Replacement cycle hire",
        c2: 1,
        c3: 0,
        c4: 0,
        c5: 0,
        c6: 1,
        c7: 0,
      },
      {
        id: 59,
        c1: "European cover",
        c2: 1,
        c3: 1,
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
      {
        id: 60,
        c1: "Worldwide cover",
        c2: 1,
        c3: 1,
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
      {
        id: 61,
        c1: "Family cover",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 0,
        c7: 0,
      },
      {
        id: 62,
        c1: "New for old",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 1,
        c6: 1,
        c7: 1,
      },

      {
        id: 64,
        c1: "Triage",
        c2: 1,
        c3: 0,
        c4: 0,
        c5: 0,
        c6: 0,
        c7: 0,
      },
      {
        id: 65,
        c1: "Physio",
        c2: 1,
        c3: "Optional",
        c4: 1,
        c5: 0,
        c6: 0,
        c7: 1,
      },
    ],
    TwoThousand: [
      {
        id: 1,
        c1: "",
        c2: (
          <div>
            Velosure <br /> <span className="greyFont">Core</span>
          </div>
        ),
        c3: (
          <div>
            Velosure <br /> <span className="greyFont">Performance</span>
          </div>
        ),
        c4: "Laka",
        c5: (
          <div>
            Yellow
            <br /> Jersey
          </div>
        ),
        c6: "Bikmo",
        c7: (
          <div>
            Direct
            <br /> Line
          </div>
        ),
      },

      {
        id: 2,
        c1: "Premium",
        c2: "£106",
        c3: "£132",
        c4: "£192",
        c5: "£157",
        c6: "£192",
        c7: "£135",
      },

      {
        id: 3,
        c1: "Accidental damage",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 1,
      },

      { id: 4, c1: "Theft", c2: 1, c3: 1, c4: 1, c5: 1, c6: 1, c7: 1 },
      {
        id: 5,
        c1: "Public liability",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: "Optional",
        c6: "Optional",
        c7: 1,
      },
      {
        id: 6,
        c1: "Accessory cover",
        c2: 1000,
        c3: 1000,
        c4: 250,
        c5: 250,
        c6: "Optional",
        c7: 250,
      },
      {
        id: 7,
        c1: "Family cover",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 0,
        c6: 0,
        c7: 0,
      },
      {
        id: 8,
        c1: "New for old",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 1,
        c7: 0,
      },
      {
        id: 9,
        c1: "Physio",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 0,
        c6: 0,
        c7: 0,
      },

      {
        id: 10,
        c1: "Triage",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 0,
        c7: 0,
      },

      {
        id: 11,
        c1: "Replacement cycle hire",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 1,
        c7: 1,
      },

      {
        id: 12,
        c1: "European cover",
        c2: 0,
        c3: 1,
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },

      {
        id: 13,
        c1: "24/7 Cycle rescue",
        c2: 0,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 0,
      },

      {
        id: 14,
        c1: "Sports cover",
        c2: 0,
        c3: 1,
        c4: "Optional",
        c5: "Optional",
        c6: "Optional",
        c7: "Optional",
      },

      {
        id: 15,
        c1: "Personal accident",
        c2: 0,
        c3: 1,
        c4: 1,
        c5: "Optional",
        c6: 0,
        c7: 0,
      },

      {
        id: 17,
        c1: "Road rage assault",
        c2: 0,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 0,
        c7: 0,
      },

      {
        id: 180,
        c1: "Worldwide cover",
        c2: 0,
        c3: 1,
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
    ],

    FourThousand: [
      {
        id: 219,
        c1: "",
        c2: (
          <div>
            Velosure <br /> <span className="greyFont">Core</span>
          </div>
        ),
        c3: (
          <div>
            Velosure <br /> <span className="greyFont">Performance</span>
          </div>
        ),
        c4: "Laka",
        c5: (
          <div>
            Yellow
            <br /> Jersey
          </div>
        ),
        c6: "Bikmo",
        c7: (
          <div>
            Direct
            <br /> Line
          </div>
        ),
      },

      {
        id: 220,
        c1: "Premium",
        c2: "£155",
        c3: "£190",
        c4: "£192",
        c5: "£206",
        c6: "£288",
        c7: "£247",
      },

      {
        id: 21,
        c1: "Accidental damage",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 1,
      },

      {
        id: 22,
        c1: "Theft",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 1,
      },
      {
        id: 23,
        c1: "Public liability",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: "Optional",
        c6: "Optional",
        c7: 1,
      },
      {
        id: 24,
        c1: "Accessory cover",
        c2: 1000,
        c3: 1000,
        c4: 250,
        c5: 250,
        c6: "Optional",
        c7: 250,
      },

      {
        id: 25,
        c1: "Family cover",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 0,
        c6: 0,
        c7: 0,
      },

      {
        id: 26,
        c1: "New for old",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 1,
        c7: 0,
      },

      {
        id: 27,
        c1: "Physio",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 0,
        c6: 0,
        c7: 0,
      },
      {
        id: 31,
        c1: "Triage",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 0,
        c7: 0,
      },
      {
        id: 32,
        c1: "Replacement cycle hire",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 1,
        c7: 1,
      },
      {
        id: 28,
        c1: "European cover",
        c2: 0,
        c3: 1,
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
      {
        id: 128,
        c1: "24/7 Cycle rescue",
        c2: 0,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 0,
      },
      {
        id: 129,
        c1: "Sports cover",
        c2: 0,
        c3: "Optional",
        c4: "Optional",
        c5: "Optional",
        c6: "Optional",
        c7: "Optional",
      },
      {
        id: 32,
        c1: "Personal accident",
        c2: 0,
        c3: "Optional",
        c4: 1,
        c5: "Optional",
        c6: 0,
        c7: 0,
      },
      {
        id: 132,
        c1: "Road rage assualt",
        c2: 0,
        c3: "Optional",
        c4: 1,
        c5: 1,
        c6: 0,
        c7: 0,
      },
      {
        id: 133,
        c1: "Worldwide cover",
        c2: 0,
        c3: "Optional",
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
    ],
    EightThousand: [
      {
        id: 19,
        c1: "",
        c2: (
          <div>
            Velosure <br /> <span className="greyFont">Core</span>
          </div>
        ),
        c3: (
          <div>
            Velosure <br /> <span className="greyFont">Performance</span>
          </div>
        ),
        c4: "Laka",
        c5: (
          <div>
            Yellow
            <br /> Jersey
          </div>
        ),
        c6: "Bikmo",
        c7: (
          <div>
            Direct
            <br /> Line
          </div>
        ),
      },

      {
        id: 20,
        c1: "Premium",
        c2: "£185",
        c3: "£225",
        c4: "£396",
        c5: "£245",
        c6: "£349",
        c7: "£324",
      },

      {
        id: 21,
        c1: "Accidental damage",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 1,
      },

      {
        id: 22,
        c1: "Theft",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 1,
      },
      {
        id: 23,
        c1: "Public liability",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: "Optional",
        c6: "Optional",
        c7: 1,
      },
      {
        id: 24,
        c1: "Accessory cover",
        c2: 1000,
        c3: 1000,
        c4: 250,
        c5: 250,
        c6: "Optional",
        c7: 250,
      },

      {
        id: 25,
        c1: "Family cover",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 0,
        c6: 0,
        c7: 0,
      },

      {
        id: 26,
        c1: "New for old",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 1,
        c7: 0,
      },

      {
        id: 27,
        c1: "Physio",
        c2: 1,
        c3: 1,
        c4: 1,
        c5: 0,
        c6: 0,
        c7: 0,
      },
      {
        id: 31,
        c1: "Triage",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 0,
        c7: 0,
      },
      {
        id: 32,
        c1: "Replacement cycle hire",
        c2: 1,
        c3: 1,
        c4: 0,
        c5: 0,
        c6: 1,
        c7: 1,
      },
      {
        id: 28,
        c1: "European cover",
        c2: 0,
        c3: 1,
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
      {
        id: 128,
        c1: "24/7 Cycle rescue",
        c2: 0,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 1,
        c7: 0,
      },
      {
        id: 129,
        c1: "Sports cover",
        c2: 0,
        c3: 1,
        c4: "Optional",
        c5: "Optional",
        c6: "Optional",
        c7: "Optional",
      },
      {
        id: 32,
        c1: "Personal accident",
        c2: 0,
        c3: 1,
        c4: 1,
        c5: "Optional",
        c6: 0,
        c7: 0,
      },
      {
        id: 132,
        c1: "Road rage assualt",
        c2: 0,
        c3: 1,
        c4: 1,
        c5: 1,
        c6: 0,
        c7: 0,
      },
      {
        id: 133,
        c1: "Worldwide cover",
        c2: 0,
        c3: 1,
        c4: "Optional",
        c5: 1,
        c6: "Optional",
        c7: "Optional",
      },
    ],
  };
};

export default CoverData;
