import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import FeatureList from "./FeatureList";
import currency from "currency.js";
const YourQuote = ({ error, coreQuote, performanceQuote, setIsLoading, showError, canProceedToPayment, showReQuote, setShowReQuote, showReQuoteMessage, setShowReQuoteMessage, fromExternalLink = false }) => {
 
    const { search } = useLocation();
    const [showPerformanceQuote, setPerformanceQuote] = useState(null);
    const [gState, setGState] = useContext(JourneyContext);
    const [highlightCore, setHighlightCore] = useState(
      gState.selectedCoreScheme === true
    );
    const [highlightPerformance, setHighlightPerformance] = useState(
      gState.selectedCoreScheme === false
    );
    const [handledPreSelection, setHandledPreSelection] = useState(false);
    const history = useHistory();

    const unSelectAll = () => {
      setHighlightCore(false);
      setHighlightPerformance(false);
      canProceedToPayment(false);
    };

    useEffect(() => {
      // users could reset the page, clearing the journey context - if this happens we want them to be returned to the step one. We'll use the bike count to test for a reset
      if (gState.bikes.length === 0) {
        setGState({ yourQuoteCrumb: 0 });
        return history.push(`/get-a-quote${search}`);
      }
    });

    useEffect(() => {
      if (fromExternalLink && performanceQuote != null) {
        setHandledPreSelection(true);
        if (gState.selectedCoreScheme === true) {
          handleCoreSelectedNoScroll();
        } else {
          handlePerformanceSelectedNoScroll();
        }
      }
    }, [handledPreSelection]);

    const handleCoreSelected = (e) => {
      e.preventDefault();
      handleCoreSelectedNoScroll();

      const ele = document.getElementById("Quote-Summary");
      const y = ele.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({ top: y, behavior: "smooth" });
    };

    function handleCoreSelectedNoScroll() {
      setHighlightCore(true);
      setHighlightPerformance(false);
      canProceedToPayment(true);
      setGState({
        ...gState,
        annualGrossPremium: coreQuote.annualGrossPremium,
        basePremium: coreQuote.basePremium,
        commission: coreQuote.commission,
        declineReason: coreQuote.declineReason,
        instalmentsApr: coreQuote.instalmentsApr,
        instalmentsFirstPayment: coreQuote.instalmentsFirstPayment,
        instalmentsGrossPremium: coreQuote.instalmentsGrossPremium,
        instalmentsInterestPc: coreQuote.instalmentsInterestPc,
        instalmentsServiceCharge: coreQuote.instalmentsServiceCharge,
        instalmentsSubsequentPayments: coreQuote.instalmentsSubsequentPayments,
        ipt: coreQuote.ipt,
        netPremium: coreQuote.netPremium,
        quoteReference: coreQuote.quoteReference,
        policyDetailsId: coreQuote.policyDetailsId,
        referralReason: coreQuote.referralReason,
        schemeId: coreQuote.schemeId,
        schemeTable: coreQuote.schemeTable,
        selectedCoreScheme: true,
      });
    }
    const handlePerformanceSelected = (e) => {
      e.preventDefault();
      handlePerformanceSelectedNoScroll();

      const ele = document.getElementById("Quote-Summary");
      const y = ele.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({ top: y, behavior: "smooth" });
    };

    function handlePerformanceSelectedNoScroll() {
      setHighlightCore(false);
      canProceedToPayment(true);
      setHighlightPerformance(true);
      setGState({
        ...gState,
        annualGrossPremium:
          showPerformanceQuote?.annualGrossPremium ??
          performanceQuote.annualGrossPremium,
        basePremium:
          showPerformanceQuote?.basePremium ?? performanceQuote.basePremium,
        commission:
          showPerformanceQuote?.commission ?? performanceQuote.commission,
        declineReason:
          showPerformanceQuote?.declineReason ?? performanceQuote.declineReason,
        instalmentsApr:
          showPerformanceQuote?.instalmentsApr ?? performanceQuote.instalmentsApr,
        instalmentsFirstPayment:
          showPerformanceQuote?.instalmentsFirstPayment ??
          performanceQuote.instalmentsFirstPayment,
        instalmentsGrossPremium:
          showPerformanceQuote?.instalmentsGrossPremium ??
          performanceQuote.instalmentsGrossPremium,
        instalmentsInterestPc:
          showPerformanceQuote?.instalmentsInterestPc ??
          performanceQuote.instalmentsInterestPc,
        instalmentsServiceCharge:
          showPerformanceQuote?.instalmentsServiceCharge ??
          performanceQuote.instalmentsServiceCharge,
        instalmentsSubsequentPayments:
          showPerformanceQuote?.instalmentsSubsequentPayments ??
          performanceQuote.instalmentsSubsequentPayments,
        ipt: showPerformanceQuote?.ipt ?? performanceQuote.ipt,
        netPremium:
          showPerformanceQuote?.netPremium ?? performanceQuote.netPremium,
        quoteReference:
          showPerformanceQuote?.quoteReference ?? performanceQuote.quoteReference,
        policyDetailsId:
          showPerformanceQuote?.quoteReference ??
          performanceQuote.policyDetailsId,
        referralReason:
          showPerformanceQuote?.referralReason ?? performanceQuote.referralReason,
        schemeId: showPerformanceQuote?.schemeId ?? performanceQuote.schemeId,
        schemeTable:
          showPerformanceQuote?.schemeTable ?? performanceQuote.schemeTable,
        selectedCoreScheme: false,
        performanceQuote: showPerformanceQuote ?? performanceQuote,
      });
    }

    return (
      <section className="container container_narrow" id='yourQuote'>

        {!!!error && (
          <div className="content_section mt-3 mb-3">
            <h4 className="mb-4">
              Your<span className="blueFont"> quotes</span>.
            </h4>

            <section id="DESKTOP_ONLY" >
              <div className="row gx-10 pr">
                <div className="col-lg-6 col-12 mb-4 mb-lg-0">
                  <div
                    className={`AnnualQuote card annual-quote-card ${highlightCore ? "greenBorder" : "blueBorder"
                      }`}
                  >
                    <div
                      className={`card-header text-center text-white p-4 
                ${highlightCore ? "greenHeader" : "blueHeader"}`}
                    >
                      <div className="whiteFont policyTitle">Core cover</div>
                      <p className="policySubTitle">
                        Ideal cover for everyday cyclist, protecting the bicycle and the rider.
                      </p>
                    </div>
                    <div className="payment-card-body">
                      <div className="d-flex justify-content-center ">

                        <h1 className="blueFont text-center QuoteValueLarge">
                          £{currency(coreQuote?.instalmentsSubsequentPayments, {
                            symbol: "",
                            separator: ",",
                          }).format()
                          }
                        </h1>
                        <div className="pl-2 pt-2">
                          <p className="greyFont lufga-light font-17">
                            per <br /> month
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center ">
                        <p className="greyFont lufga-light font-17">
                          or <span className="quoteBlueSubHeader">    £{currency(coreQuote?.annualGrossPremium, {
                            symbol: "",
                            separator: ",",
                          }).format()
                          } </span> per year
                        </p>
                      </div>

                    <div className="d-flex justify-content-center">
                      <p className="quoteBlueSubHeader">
                        <span className="footer-darkgrey">What is</span> included<span className="footer-darkgrey">.</span>
                      </p>
                    </div>
                  </div>
                  <div className="pl-2 pb-lg-4">
                    <FeatureList isCore={true} unSelectAll={unSelectAll} initPerformanceQuote={gState?.initQuote ?? performanceQuote} setIsLoading={setIsLoading} setPerformanceQuote={setPerformanceQuote} />
                  </div>
                  <div className={ (!highlightCore && !highlightPerformance && showError && showError ? "coreLeftError " : "coreLeft ") + "position-absolute bottom-0 d-lg-block d-none"} >
                    <div className="d-flex justify-content-center align-content-center mt-1 mb-4">
                      <button
                        className={`btn  btn-wider ${highlightCore ? "btn-green" : "btn-primary"
                          }`}
                        onClick={handleCoreSelected}
                      >
                        {highlightCore ? "Selected" : "Select"}
                      </button>

                      </div>
                      {!highlightCore && !highlightPerformance && showError && showError && <div className="text-center"> <small className="redFont my-1 ">
                        Please select a cover type to proceed
                      </small></div>}
                    </div>
                    <div className="d-lg-none d-block">
                      <div className="d-flex justify-content-center align-content-center mt-1 mb-4">
                        <button
                          className={`btn  btn-wider ${highlightCore ? "btn-green" : "btn-primary"
                            }`}
                          onClick={handleCoreSelected}
                        >
                          {highlightCore ? "Selected" : "Select"}
                        </button>

                      </div>
                      {!highlightCore && !highlightPerformance && showError && showError && <div className="text-center"> <small className="redFont my-1 ">
                        Please select a cover type to proceed
                      </small></div>}
                    </div>
                  </div>
                </div>

                <div className="or">

                </div>

                {/* ====performance===============================================================  */}
                <div className="col-lg-6 col-12">
                  <div
                    className={`MonthlyQuote card monthly-quote-card ${highlightPerformance ? "greenBorder" : "blueBorder"
                      }`}
                  >
                    <div
                      className={`card-header text-center text-white p-4 
                ${highlightPerformance ? "greenHeader" : "blueHeader"}`}
                    >
                      <div className="whiteFont policyTitle">Performance cover</div>
                      <p className="policySubTitle">
                        The perfect cover for the enthusiast cyclist, with added elements of cover included.
                      </p>
                    </div>
                    <div className="payment-card-body">
                      <div className="d-flex justify-content-center ">

                        <h1 className="blueFont text-center QuoteValueLarge">
                          £{currency(showPerformanceQuote?.instalmentsSubsequentPayments != null ? showPerformanceQuote?.instalmentsSubsequentPayments : highlightCore ? performanceQuote?.instalmentsSubsequentPayments : gState?.instalmentsSubsequentPayments ?? performanceQuote?.instalmentsSubsequentPayments, {
                            symbol: "",
                            separator: ",",
                          }).format()
                          }
                        </h1>
                        <div className="pl-2 pt-2">
                          <p className="greyFont lufga-light font-17">
                            per <br /> month
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center ">
                        <p className="greyFont lufga-light font-17">

                          or <span className="quoteBlueSubHeader">  £{currency(showPerformanceQuote?.annualGrossPremium != null ? showPerformanceQuote.annualGrossPremium : highlightCore ? performanceQuote?.annualGrossPremium : gState?.annualGrossPremium ?? performanceQuote?.annualGrossPremium, {
                            symbol: "",
                            separator: ",",
                          }).format()
                          } </span> per year
                        </p>
                      </div>
                      <div className="d-flex justify-content-center">
                        <p className="quoteBlueSubHeader">
                          <span className="footer-darkgrey">What is</span> included<span className="footer-darkgrey">.</span>
                        </p>
                      </div>
                    </div>
                    <div className="pl-2 pb-lg-4">
                    <FeatureList
                    isCore={false}
                    unSelectAll={unSelectAll}
                    initPerformanceQuote={gState?.initQuote ?? performanceQuote}
                    setIsLoading={setIsLoading}
                    showReQuote={showReQuote}
                    setShowReQuote={setShowReQuote}
                    showReQuoteMessage={showReQuoteMessage}
                    setShowReQuoteMessage={setShowReQuoteMessage}
                    setPerformanceQuote={setPerformanceQuote}
                  />                    </div>
                    <div className="d-flex justify-content-center align-content-center mt-1 mb-4">
                      <button
                        className={`btn  btn-wider ${highlightPerformance ? "btn-green" : "btn-primary"
                          }`}
                        onClick={handlePerformanceSelected}
                      >
                        {highlightPerformance ? "Selected" : "Select"}
                      </button>

                    </div>
                    {!highlightCore && !highlightPerformance && showError && <div className="text-center"> <small className="redFont my-1 ">
                      Please select a cover type to proceed
                    </small></div>}
                  </div>
                </div>
              </div>
            </section>


          </div>
        )}

        {error && (
          <h4 id="quote-retrieval-failed" className="content_section mt-5 mb-5">
            {error}
          </h4>
        )}
      </section>
    );

  };
  export default YourQuote;
