import { Link } from "react-router-dom";

const CTAButton = ({ align, colour, CTAText, Url, id = "", externalLink = false, additionalClass = "" }) => {
  let showButton = true;

  const GetAlign = () => {
    switch (align) {
      case "left":
        return "align-left!important";
      case "right":
        return "align-right!important;";
      case "center":
        return "align-center";
      default:
        return "align-center";
    }
  };

  const GetColour = () => {
    switch (colour) {
      case "blue":
        return "btn-primary";
      case "green":
        return "btn-green";
      case "orange":
        return "orangeBG";
      case "grey":
        return "greyBG";
      case "mustard":
        return "mustardWhiteBG btn-mustard ";
      default:
        return "left";
    }
  };

  return (
    <div className={GetAlign(align)}>
      {showButton === true && externalLink === false && (
        <Link className={"btn lufga CTAButton  " + GetColour(colour) + " " + additionalClass} to={Url} id={id} >
          {CTAText}
        </Link>
      )}
      {showButton === true && externalLink === true && (
        <a className={"btn lufga CTAButton  " + GetColour(colour) +" " +additionalClass } href={Url} id={id} target="_blank" rel="noreferrer">
      {CTAText}
    </a>
  )
}
    </div >
  );
};

export default CTAButton;
