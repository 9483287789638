import { MantineProvider } from "@mantine/core";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./CSS/site.css";
import ScrollToTop from "./MainSite/ScrollToTop";
import CookieBar from "./Shared/CookieBar";
import Footer from "./Shared/Footer";
import SOBQueryParamHandler from "./Shared/SOBQueryParamHandler";
import Routes from "./routes";

const App = () => {
  return (
    <MantineProvider
      theme={{
        colors: {
          "velo-blue": [
            "#00a8ff",
            "#00a8ff",
            "#00a8ff",
            "#00a8ff",
            "#00a8ff",
            "#00a8ff",
            "#00a8ff",
            "#00a8ff",
            "#00a8ff",
            "#00a8ff",
          ],
          "velo-green": [
            "#86bb2f",
            "#86bb2f",
            "#86bb2f",
            "#86bb2f",
            "#86bb2f",
            "#86bb2f",
            "#86bb2f",
            "#86bb2f",
            "#86bb2f",
            "#86bb2f",
          ],
        },
      }}
    >
      <Router>
        <div className="App">
          <div className="container-fluid">
            <ScrollToTop>
              <CookieBar />
              <SOBQueryParamHandler />
              {/* <BootstrapBreakpointVisualiser isVisible={process.env.REACT_APP_SHOW_RESPONSIVE_TOOL === 'true'}> */}
              <Routes />
              {/* </BootstrapBreakpointVisualiser> */}
            </ScrollToTop>
          </div>
          <Footer />
        </div>
      </Router>
    </MantineProvider>
  );
};

export default App;
