// Collection of helper functions used to process the Global state object in order to produce
// a data object that can be passed to transactor.
const ModelAdaptorHelper = {
  getTitleId: (title) => {
    if (title === undefined || title === "" || title == null) return 0;
    switch (title) {
      case "mr":
        return "003";
      case "mrs":
        return "004";
      case "miss":
        return "002";
      case "ms":
        return "005";
      case "dr":
        return "001";
      case "mx":
        return "106";
      default:
        return "003";
    }
  },
  removeBrackets: (textToStrip) => {
    if (textToStrip === null  || textToStrip === undefined) return "";
    let returnString = textToStrip.replaceAll(")", " ").replaceAll("(", " ");
    return returnString;
  },
  removeNullText: (textToStrip) => {
    if (textToStrip === null || textToStrip === "" || textToStrip === undefined) return "";
    return textToStrip.replaceAll("null", " ");
  },
  getHomeValue: (bikeList) => {
    if (!bikeList) return 0;
    let total = 0;

    for (const bike in bikeList) {
      if (bikeList.hasOwnProperty.call(bikeList, bike)) {
        total = total + bikeList[bike].value;
      }
    }

    return total;
  },
  getLockName: (bikeValue) => {
    if (bikeValue > 2500) return "Gold rated lock";
    if (bikeValue > 1500) return "Silver rated lock";
    return "Bronze rated lock";
  },
  allBikeLocksChecked: (bikeList) => {
    // if any checkboxes are not checked return false
    bikeList.forEach((bike) => {
      if (bike.lockChecked === false) return false;
    });
    return true;
  },
  getBikeCollection: (bikeList) => {
    // build up an object for the bike list in the specific format ie
    //  [{make.model,value},{make.model,value},]

    let bikeCollection = [];

    for (const bike in bikeList) {
      if (bikeList.hasOwnProperty.call(bikeList, bike)) {
        let bikeMake = bikeList[bike].make;
        let bikeModel = bikeList[bike].model;
        let bikeValue = bikeList[bike].value;

        bikeCollection.push({
          make: bikeMake,
          model: bikeModel,
          homeValue: bikeValue,
          serialNumber: "",
          purchaseDate: null,
          hasDataTagFitted: null,
        });
      }
    }
    return bikeCollection;
  },
  getFormattedDOBFromDateParts: (d, m, y) => {
    return new Date(Date.UTC(y, m, d));
  },
  getStorageLocation: (id) => {
    if (id === null) return "Home";

    switch (id) {
      case 1:
        return "Home";
      case 2:
        return "Outbuilding";
      case 3:
        return "Communal Area";
      case 4:
        return "Other";
      default:
        return "Home";
    }
  },

  getStorageLocationFromId: (locationId) => {
    switch (locationId) {
      case 1:
        return "Home";
      case 2:
        return "Outbuilding";
      case 3:
        return "Communal area";
      case 4:
        return "Other";
      default:
        return "Not found";
    }
  },

  resetAssumptionsAndDeclarations(gState) {
    gState.eBikeTicked = false;
    gState.assumptionsTicked = false;
    gState.storageLocationTicked = false;
    gState.readDocumentsTicked = false;
    gState.bikes = gState.bikes.map((bike) => {
      bike.lockChecked = false;
      return bike;
    });
    return gState;
  },
  HowManyBikes(collection) {
    return collection.length;
  },
  AnyElectricBikes(collection) {
    return collection.length > 1 ? false : collection[0].isElectric;
  },
  PhraseSanctionResults(response){

    var Success = response?.data?.searchRecord?.listSearchResults?.length == 0 && response?.data?.responseStatus?.errorCode == null;
    var Failure = response?.data?.searchRecord?.listSearchResults?.length >= 1;
    var Error = response?.data?.responseStatus?.errorCode != null;

    return {
      Success:Success,
      Failure:Failure,
      Error:Error
    }
  }

};

export default ModelAdaptorHelper;
