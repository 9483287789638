const ComparisonTableRow = (props) => {
  const data = props.row;
  //console.log("desktop");
  const fillColour = props.theme;
  const themeClass = props.themeClass;

  const formatCol = (text) => {
    switch (text) {
      case 0:
        return "-";
      case 1:
        return (
          <div>
            <svg width="23" height="23" viewBox="0 0 23 23">
              <g transform="translate(-1125 -526)">
                <circle
                  cx="11.5"
                  cy="11.5"
                  r="11.5"
                  transform="translate(1125 526)"
                  fill={fillColour}
                />
                <path
                  id="Path_1875"
                  d="M217.815,191.824l3.062,3.062.306-.306,7.246-7.246"
                  transform="translate(913.176 346.189)"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="3"
                />
              </g>
            </svg>
          </div>
        );
      case 2:
        return "-";
      case 1000:
        return (
          <div>
            <svg width="23" height="23" viewBox="0 0 23 23">
              <g transform="translate(-1125 -526)">
                <circle
                  cx="11.5"
                  cy="11.5"
                  r="11.5"
                  transform="translate(1125 526)"
                  fill={fillColour}
                />
                <path
                  id="Path_1875"
                  d="M217.815,191.824l3.062,3.062.306-.306,7.246-7.246"
                  transform="translate(913.176 346.189)"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="3"
                />
              </g>
            </svg>
            <p className={`smallPrint1000 ${themeClass}`}></p>
          </div>
        );
      case 250:
        return (
          <div>
            <svg width="23" height="23" viewBox="0 0 23 23">
              <g transform="translate(-1125 -526)">
                <circle
                  cx="11.5"
                  cy="11.5"
                  r="11.5"
                  transform="translate(1125 526)"
                  fill={fillColour}
                />
                <path
                  id="Path_1875"
                  d="M217.815,191.824l3.062,3.062.306-.306,7.246-7.246"
                  transform="translate(913.176 346.189)"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="3"
                />
              </g>
            </svg>
            <p className={`smallPrint250 ${themeClass}`}></p>
          </div>
        );
      case 3:
        return "Optional";
      default:
        return text;
    }
  };

  return (
    <tr>
      <td>
        <div className="titleCell">{formatCol(data.c1)}</div>
      </td>
      <td>
        <div className={`comparisonCell ${themeClass}`}>
          {formatCol(data.c2)}
        </div>
      </td>
      <td>
        <div className={`comparisonCell ${themeClass}`}>
          {formatCol(data.c3)}
        </div>
      </td>
      <td>
        <div className={`comparisonCell`}>{formatCol(data.c4)}</div>
      </td>
      <td>
        <div className={`comparisonCell`}>{formatCol(data.c5)}</div>
      </td>
      <td>
        <div className={`comparisonCell`}>{formatCol(data.c6)}</div>
      </td>
      <td>
        <div className={`comparisonCell`}>{formatCol(data.c7)}</div>
      </td>
    </tr>
  );
};

export default ComparisonTableRow;
