import { useState, useEffect } from "react";
import LoggingService from "../Services/LoggingService";
const useFetch = (url, options, doNotRun = false) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!doNotRun) {

      setTimeout(() => {
        fetch(url, options)
          .then((res) => {
            if (!res.ok) {
              LoggingService.logError(JSON.stringify(res));
              throw Error("No data gathered from that resource");
            }

            return res.json();
          })
          .then((data) => {
            LoggingService.logInfo(`Called fetch for ${url} and get response ${JSON.stringify(data)}`)
            setData(data);
            setIsPending(false);
            setError(null);
          })
          .catch((err) => {
            LoggingService.logInfo(`Called fetch for ${url} errored :${err}`)
            setIsPending(false);
            setError(err.message);
          });
      }, 1000);
    }
    else {
      setIsPending(false)
    }
  }, [url]); //anytime the url changes, this function will fire

  return { data, isPending, error };
};

export default useFetch;
