const Spinner = ({ colour = "velo-green" }) => (
  <div
    className="spinner-border text-dark"
    role="status"
    style={{ borderRightColor: `var(--${colour})` }}
  >
    <span className="visually-hidden">Loading...</span>
  </div>
);

export default Spinner;
