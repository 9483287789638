import { useContext, useState } from "react";
import { JourneyContext } from "../../CONTEXT/JourneyStore";

function PreferenceButton({ label, isActive, onClick }) {
  return (
    <button
      type="button"
      onClick={() => {
        onClick();
      }}
      className="preferredMethodOfContact preference-icon"
      style={
        isActive
          ? { backgroundColor: "#00a8ff", color: "white" }
          : { backgroundColor: "#efefef" }
      }
    >
      {label}
    </button>
  );
}

const MarketingPreferences = ({ formik }) => {
  const [gState, setGState] = useContext(JourneyContext);
  // const preferPhoneCode = "3EHPHIF7";
  // const preferEmailCode = "3EHPHID7";
  //const [optIn, setOptIn] = useState(gState.optIn);
  // const [isMobile, setIsMobile] = useState(window.visualViewport.width < 770);

  const [thirdPartyPhone, setThirdPartyPhone] = useState(
    gState.thirdPartyPhone
  );
  const [thirdPartyEmail, setThirdPartyEmail] = useState(
    gState.thirdPartyEmail
  );
  const [adminPhone, setadminPhone] = useState(gState.adminPhone);
  const [adminEmail, setadminEmail] = useState(gState.adminEmail);

  // // const updateMarketingOptIn = (e) => {
  // //   setOptIn(e.target.checked);
  // //   formik.setFieldValue("marketingOptIn", e.target.checked);
  // // };

  const handleThirdPartyEmailClick = () => {
    setGState({ ...gState, thirdPartyEmail: !gState.thirdPartyEmail });
    setThirdPartyEmail(!thirdPartyEmail);
  };
  const handleThirdPartyPhoneClick = () => {
    setGState({ ...gState, thirdPartyPhone: !gState.thirdPartyPhone });
    setThirdPartyPhone(!thirdPartyPhone);
  };
  const handleAdminEmailClick = () => {
    setGState({ ...gState, adminEmail: !gState.adminEmail });
    setadminEmail(!adminEmail);
  };
  const handleAdminPhoneClick = () => {
    setGState({ ...gState, adminPhone: !gState.adminPhone });
    setadminPhone(!adminPhone);
  };

  return (
    <div className="content_section mt-3">
      <h3 className="journey-section-titles  mb-4">
        Marketing<span className="blueFont"> preferences</span>.
      </h3>
      <p className="lufga-light mb-4">
        We’d like to keep you up to date with the latest products, services, and
        exclusive offers and competitions from Velosure. We securely protect
        your personal data. You are welcome to unsubscribe at any time. If you
        wish to receive this information, please select your preferred method of
        contact:
      </p>

      <div className="d-flex mb-5">
        <PreferenceButton
          label="Telephone"
          isActive={adminPhone}
          onClick={handleAdminPhoneClick}
        />

        <PreferenceButton
          label="Email"
          isActive={adminEmail}
          onClick={handleAdminEmailClick}
        />
      </div>

      <h3 className="journey-section-titles mb-4">
        Our<span className="blueFont"> partners</span>
      </h3>

      <p className="lufga-light mb-4">
        We would also like to pass your details to our partners in The Connexus
        Group*. We will not pass your personal details outside The Connexus
        Group, and we always securely protect your personal data. If you wish to
        receive information from our partners, please tick below. You can
        unsubscribe at any time.
      </p>

      <div className="d-flex mb-5">
        <PreferenceButton
          label="Telephone"
          isActive={thirdPartyPhone}
          onClick={handleThirdPartyPhoneClick}
        />

        <PreferenceButton
          label="Email"
          isActive={thirdPartyEmail}
          onClick={handleThirdPartyEmailClick}
        />
      </div>

      <p className="lufga-light font-12 lightgreyFont mt-4">
        *The Connexus Group includes: Carbon Insurance Brokers who offer a wide
        variety of insurance products. Performance Car Hire who offer prestige
        and executive vehicles for hire. Connexus Medical Appointments who
        arrange medical examinations via our national panel of over 1500
        members. Connexus Health and Rehabilitation who offer bespoke treatment
        packages to suit businesses and individuals, including physiotherapy and
        counselling services. KLS Law who offer a range of legal services.
      </p>
    </div>
  );
};

export default MarketingPreferences;
