
import helper from "../HELPERS/ModelAdaptorHelper";
import VeloAPIService from "./VeloAPIService";

const SanctionsSearchService = {

    async SanctionSearch(gState) {
        var reference =`Velsoure:Bike Insurance:${gState.quoteReference}`;
        var fullRequest = {
            request: {
                "type": "individual",
                "name": `${gState.forename} ${gState.surname}`,
                "address": `${gState.houseNo?.length >= 1 ? gState.houseNo.trim() : ""
                    } ${gState.houseNo?.length < 1 && gState.houseName?.length >= 1 ? gState.houseName.trim() : ""
                    }, ${gState.postcode}`
                    .trim()
                    .replace("  ", " "),
                "dateOfBirth": null,//`${gState.dob_d.length === 1 ?"0"+gState.dob_d : gState.dob_d }/${helper.getFullMonthNumberFromMonthString(gState.dob_m)}/${gState.dob_y}`,
                "selectedLists": [
                    "ALL"
                ],
                "Reference": `Velsoure:Bike Insurance:${gState.quoteReference}`
            },
            authentication: {
                "ApiUserId": process.env.REACT_APP_SanctionsSearchAPIUserId,
                "ApiUserKey": process.env.REACT_APP_SanctionsSearchAPIUserKey
            }
        }
        const options = {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(fullRequest),
        };
        const response = await fetch(`${process.env.REACT_APP_SanctionsSearchUri}`, options);
        var data = await response.json();
        var phrasedSanctionResults = helper.PhraseSanctionResults(data);
        phrasedSanctionResults.Reference = reference;
        phrasedSanctionResults.PolicyDetailsId = gState.policyDetailsId;
        try {
            await VeloAPIService.AddTransactorNotes(phrasedSanctionResults);
        } catch {}
   
        return phrasedSanctionResults;
    }
}

export default SanctionsSearchService;