//Service for processing payments

import RestApiCommBaseService from "./RestApiCommBaseService";
import ModelAdaptorHelper from "../HELPERS/ModelAdaptorHelper";
  
const PaymentService = {

  validatePayment: (payload) => {
    const opts = {
      method: "POST",
      headers: {
        accept: "text/plain",
        "X-API-KEY": process.env.REACT_APP_VELOSURE_API_KEY,
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    return fetch(
      `${process.env.REACT_APP_VELOSURE_API_URL}/api/Payment/ValidatePayment`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
 
  getHppAccessToken: (payload) => {
    const opts = {
      method: "POST",
      headers: {
        accept: "text/plain",
        "X-API-KEY": process.env.REACT_APP_VELOSURE_API_KEY,
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    return fetch(
      `${process.env.REACT_APP_VELOSURE_API_URL}/api/Payment/RetrieveAccessToken`,
      opts
    )
      .then(RestApiCommBaseService.handleResponse)
      .then((json) => {
        return json;
      });
  },
  rebuildPaymentIframe: () => {
    document.getElementById("payIframe").remove();

    const iframe = document.createElement("iframe");

    iframe.id = "payIframe";
    iframe.style.width = "100%";
    iframe.style.height = "466px";
    iframe.style.margin = "0 auto!important";
    iframe.style.border = "none";

    const cardPaymentForm = document.getElementById("card-payment-form");
    cardPaymentForm.childNodes[0].appendChild(iframe);
  },
  getCycleInsurancePurchase: (purchaseData) => {
    console.log("purchaseData");

    return {
      houseNameNumber: ModelAdaptorHelper.removeBrackets(purchaseData.houseNo),
      addressLine1: ModelAdaptorHelper.removeBrackets(purchaseData.addressLine1),
      addressLine2: ModelAdaptorHelper.removeBrackets(purchaseData.addressLine2),
      townCity: ModelAdaptorHelper.removeBrackets(purchaseData.addressLine3),
      postCode: purchaseData.postcode,
      email: purchaseData.email,
      telephone: purchaseData.telephoneNo,
      amountToCharge: purchaseData.annualGrossPremium,
    };
  },
  bottomLineCheck: (sortCode, accNo) => {
    const opts = {
      method: "GET",
      headers: {
        accept: "text/plain",
        "X-API-KEY": process.env.REACT_APP_VELOSURE_API_KEY,
        "content-type": "application/json",
      },
    };

    return fetch(
      `${process.env.REACT_APP_VELOSURE_API_URL}/api/BankDetails/ValidateUkBankAccount?sortCode=${sortCode}&accNo=${accNo}`,
      opts
    );
    // .then((res) => {
    //   console.log(res);
    //   if (!res.ok) {
    //     throw Error("No data gathered from that resource");
    //   }
    //   return res.json();
    // })
  },
};

export default PaymentService;
