import TopNavBar from "../../Shared/TopNavBar";
import RegularBanner from "../SHARED/RegularBanner";
import WhatOurCustomersSay from "../SHARED/WhatOurCustomersSay";
import ProtectYourBike from "../SHARED/ProtectYourBike";
import HowDoesThisBenefit from "./HowDoesThisBenefit";
import RepairReplace from "./RepairReplace";
import AlreadyAnAffiliate from "./AlreadyAnAffiliate";
import CustomerLoyalty from "./CustomerLoyalty";
import LastingRelationship from "./LastingRelationship";
import ReadyToSignUp from "./ReadyToSignUp";
import { createRef } from "react";
import { useEffect } from "react";
import { seoTags } from "../../Shared/SeoEdit";
import { Helmet } from "react-helmet";

const RetailerAffiliate = () => {
  const signupRef = createRef();
  useEffect(() => {
    seoTags(
      "Retailer Affiliate Scheme",
      "Velosure cycle insurance work with retailers to enable better protection for their customers. ",
      ""
    );
    return () => {
      seoTags("velosure ", "", "");
    };
  }, []);
  return (
    <div className="container-fluid">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.velosure.co.uk/affiliate-scheme"
        />
      </Helmet>
      <TopNavBar theme={"white"} />
      <RegularBanner
        headlineLine1={"Retailer"}
        headlineLine2={"affiliate scheme"}
        subheadlineLine1={
          "Our affiliate scheme supports both cyclists and local cycle shops"
        }
        subheadlineLine2={""}
        hasCTA={"false"}
        hasScrollCTA={true}
        scrollCTARef={signupRef}
        CTAText={"Sign up"}
      />

      <HowDoesThisBenefit />
      <RepairReplace />
      <AlreadyAnAffiliate />
      <LastingRelationship signupRef={signupRef} />
      <CustomerLoyalty />
      <ReadyToSignUp signupRef={signupRef} />
      <WhatOurCustomersSay />
      <ProtectYourBike variant={1} />
    </div>
  );
};

export default RetailerAffiliate;
