const WhatOurCustomersSayList = () => {
  return (
    <div className="container-fluid whiteBG oh pr">
      <div className="container mt-5 mb-5">
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <div class="elfsight-app-67c39d73-9a57-4545-90dd-436b395ee4a0"></div>
      </div>
    </div>
  );
};

export default WhatOurCustomersSayList;
